import React from 'react'
import Add from '@material-ui/icons/Add'
import { Close } from '@material-ui/icons'
import {
  Equipment,
} from 'types'
import { useEffect, useState } from 'react'
import Stack from 'ui/stack'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import Muted from './muted'
import { humanize } from 'utils/string-utils'
import AddNew from './install/add-new'

/**
 * equipment needs to be divided into two if it's venitlating dehumidifier
 */
const ScheduleEquipment = ({
  equipment,
  availableEquipment,
  initialEquipment,
  onChangeEquipment,
}) => {
  const _initialEquipment = initialEquipment.length ? initialEquipment : availableEquipment.length ? [availableEquipment[0]] : []
  const [selectedEquipment, setSelectedEquipment] = useState<Equipment[]>(_initialEquipment)
  const available = availableEquipment.filter(x => !selectedEquipment.some(y => y.id === x.id))

  const handleChangeEquipment = index => event => {
    const { value } = event.target
    const _value = parseInt(value)
    const selection = availableEquipment.find(x => x.id === _value)

    setSelectedEquipment(prev =>
      prev.map((x, i) => i === index ? selection : x))
  }

  const onClickMoreEquipment = () => {
    if (!available.length) return
    setSelectedEquipment(prev => [...prev, available[0]])
  }

  const onClickRemoveEquipment = (index) => () => {
    setSelectedEquipment(prev => prev.filter((x, i) => i !== index))
  }

  useEffect(() => {
    if (!initialEquipment?.length) return
    setSelectedEquipment(initialEquipment)
  }, [JSON.stringify(initialEquipment)])

  useEffect(() => {
    onChangeEquipment(selectedEquipment)
  }, [selectedEquipment.map(x => x.id).join('')])

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        {selectedEquipment.map((equip, index) => (
          <Box
            display='flex'
            alignItems='center'
            key={equip.id}
          >
            <FormControl
              id={`equipment_select_${equip.id}`}
              size='small'
              variant='outlined'
              style={{
                width: '100%',
              }}
            >
              <InputLabel
                id={`equipment_select_${equip.id}`}>Equipment</InputLabel>
              <Select
                id={`equipment_select_${equip.id}`}
                name={`equipment_select`}
                labelId={`equipment_select`}
                label='Equipment'
                value={equip.id || ''}
                onChange={handleChangeEquipment(index)}
              >
                <MenuItem
                  disabled
                  value={``}
                >
                  Select...
                </MenuItem>
                {equipment.map(availableEquip => (
                  <MenuItem
                    key={availableEquip.id}
                    disabled={!available.some(x => x.id === availableEquip.id)}
                    value={`${availableEquip.id}`}
                  >
                    <Box display='flex' flexDirection='column'>
                      {humanize(availableEquip.type)}
                      <Muted style={{ fontSize: '14px' }}>{availableEquip.brand}</Muted>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(selectedEquipment.length > 1) ? (
              <IconButton
                onClick={onClickRemoveEquipment(index)}
                size='small'
              >
                <Close />
              </IconButton>
            ) : null}
          </Box>
        ))}
        {!available.length ? (
          null
        ) : (
          <AddNew
            onClick={onClickMoreEquipment}
          >
            Add equipment
          </AddNew>
        )}
      </Stack>
    </Stack>
  )
}

export default ScheduleEquipment
