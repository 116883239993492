import * as React from 'react'
import Stack from "ui/stack";
import AutomationRuleCard from "./automation-rule-card";
import RuleTitle from "./ruleTitle";
import Override from "./override";
import { selectRule, useEquipmentStore, useRulesStore } from './store';
import { Box, Button, Typography } from '@material-ui/core';
import Dot from './dot';

import { humanize } from 'utils/string-utils';
import StaticInput, { findEquipSrc } from './static-input';

function StaticRuleWithNesting({ ruleId, handleClickEdit }) {
    const rule = useRulesStore(selectRule(ruleId))

    return (
        <AutomationRuleCard>
            <Stack direction='row'>
                <RuleTitle>
                    Activate
                </RuleTitle>
                <Override
                    ruleId={ruleId}
                />
            </Stack>
            {Object.values(rule.outputs.filter(x => x.equipment) || {})
                .map((x, i) => (
                    <Stack
                        key={x.id}
                        direction='row'>
                        <Box display='flex' gridGap='8px'>
                            <img src={findEquipSrc(x.equipment?.type)} />
                            <Typography variant='body1'>
                                {humanize(x.equipment?.type)}
                            </Typography>
                        </Box>
                        <Dot active={Boolean(x.currently_triggered)} />
                    </Stack>
                ))}
            {rule.inputs.map((input, index) => (
                <Stack
                    style={{ marginTop: '8px' }}
                    key={input.id}
                    spacing={1}
                >
                    <RuleTitle>
                        {(index > 0) ? 'And' : 'When'}
                    </RuleTitle>
                    <StaticInput ruleId={rule.id} inputId={input.id} />
                </Stack>
            ))}
            <Button size='small' onClick={handleClickEdit}>Edit</Button>
        </AutomationRuleCard>
    )
}

export default StaticRuleWithNesting
