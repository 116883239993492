import { useEffect, useState } from "react"
import httpService from "state-mngt/services/data/http-service"
import dwellingService from "state-mngt/services/dwelling-service"
import useDwellingStore from "stores/dwelling"
import {
  Automation,
  AutomationInput,
  AutomationLog,
  AutomationOutput,
  AutomationRule,
  Device,
  Equipment,
} from "types"
import useCurrentDwellingContents from "utils/hooks/useCurrentDwellingContents"
import {
  formatForStore,
  useAutomationsStore,
  useDevicesStore,
  useEquipmentStore,
  useLogsStore,
  useRulesStore,
} from "./store"
import useAutomationIds from "./useAutomationIds"
import { automationsToRules } from "./util"
import { create } from "zustand"
import { findEquipmentComponent } from "stores/util"

interface AutomationLogResponse {
  num: number
  logs: AutomationLog[]
}

export const dedupe = (arr: any[]) => Array.from(new Set(arr))

const falsyObj = x => x ? x : ({
})

const useLoadingStore = create(() => true)
const setLoading = loading => useLoadingStore.setState(loading)

/**
 * get all the data for the customer tab
 */
const useGetData = (dwellingId) => {
  const loading = useLoadingStore()
  const [outputs, setOutputs] = useState<AutomationOutput[]>([])
  const [inputs, setInputs] = useState<AutomationInput[]>([])
  const automationIds = useAutomationIds(dwellingId)

  const setRules = useRulesStore.setState
  const setEquipment = useEquipmentStore.setState
  const setAutomations = useAutomationsStore.setState
  const setDevices = useDevicesStore.setState
  const setDwelling = (id, data) => useDwellingStore.setState(prev => ({
    ...prev,
    [id]: {
      ...prev[id],
      ...data,
    },
  }))
  const automations = useAutomationsStore.getState()
  const setLogs = logs => useLogsStore.setState(formatForStore<AutomationLog>(falsyObj(logs)))

  const dwellingContents = useCurrentDwellingContents()
  const devices = dwellingContents?.devices

  useEffect(() => {
    if (!dwellingId) return

    const get = async () => {
      try {
        const [dwelling, contents, permissions] = await Promise.all([
          dwellingService.getDwelling(dwellingId),
          dwellingService.getDwellingContents(dwellingId),
          dwellingService.getDwellingPermission(dwellingId),
        ])

        const update = {
          dwelling,
          contents,
          permissions,
        }

        setDwelling(dwellingId, update)
      } catch (e) {
        console.error('[useGetData] ', e)
      }
    }

    get()
  }, [dwellingId])

  useEffect(() => {
    if (!devices?.length) return

    const get = async () => {
      const deviceIds = devices.map(x => x.id)

      const deviceProms = deviceIds.map(id =>
        httpService.get<Device>(`/device/${id}`))

      try {
        const response = await Promise.all(deviceProms)
        setDevices(formatForStore<Device>(response))
      } catch (e) {
        console.error('[useGetData] ', e)
      }
    }

    get()
  }, [devices?.map(x => x.id).join('')])

  useEffect(() => {
    if (Array.isArray(automationIds) && automationIds.length === 0) setLoading(false)
  }, [automationIds])

  useEffect(() => {
    if (!automationIds) return
    if (!automationIds.length) return

    const getLogs = async () => {
      const oneYearAgo = new Date(new Date()
        .setFullYear(new Date().getFullYear() - 1))
        .toISOString()

      try {
        const proms = automationIds.map(x =>
          httpService
            .get<AutomationLogResponse>(`/automation/${x}/log?start_timestamp=${encodeURIComponent(oneYearAgo)}`),
        )
        const r = await Promise.all(proms)
        const logs = r.flatMap(x => x.logs)
        setLogs(logs)
      } catch (e) {
        console.error('[useGetData] ', e)
      }
    }

    getLogs()
  }, [automationIds?.join('')])

  useEffect(() => {
    if (!automationIds) return

    const get = async () => {
      const proms = automationIds
        .map(id =>
          httpService.get<Automation>(`/automation/${id}`),
        )
      try {
        const data = await Promise.all(proms)
        const _data = data
          .map((x, i) => ({
            ...x,
            id: automationIds[i],
          }))
          .filter(x => x.outputs.some(x => x.type !== 'slack'))

        if (!_data.length) return setLoading(false)

        setAutomations(formatForStore(_data))
        setOutputs(_data.map(automation => automation.outputs).flatMap(x => x))
        setInputs(_data.map(automation => automation.inputs).flatMap(x => x))
      } catch (e) {
        console.error('[useGetData] ', e)
        setLoading(false)
      }
    }

    get()
  }, [
    automationIds?.join(''),
  ])

  useEffect(() => {
    if (!Object.keys(automations).length) return
    const _rules = automationsToRules(Object.values(automations))
    setRules(formatForStore<AutomationRule>(_rules))
  }, [
    Object.keys(automations)?.join(''),
  ])

  useEffect(() => {
    if (!outputs.length) return
    if (!inputs.length) return
    if (!devices?.length) return

    const get = async () => {
      try {
        const equipmentIds = dedupe([...outputs, ...devices]
          .map(x => x.equipment_id)
          .filter(x => x))

        const equipProms = equipmentIds.map(id =>
          httpService.get<Equipment>(`/equipment/${id}`))

        const equipment = await Promise.all(equipProms)
        const airflowMonitor = devices.find(x => x.type === 'cam')

        const _equipment = equipment.map(equip => {
          if (airflowMonitor?.equipment_id === equip.id) {
            return {
              ...equip,
              airflowEquipment: true,
            }
          }
          return equip
        })

        setEquipment(formatForStore<Equipment>(_equipment))
      } catch (e) {
        console.error('[useGetData] ', e)
      }

      setLoading(false)
    }

    get()
  }, [
    JSON.stringify(outputs),
    JSON.stringify(inputs),
    JSON.stringify(devices),
  ])

  return {
    loading,
  }
}

export {
  useLoadingStore,
  automationsToRules,
}

export default useGetData
