import * as React from 'react'
import { selectInput, useRulesStore } from './store'
import useConvertTemperatureUnits from 'utils/hooks/useConvertTemperatureUnits'
import { Box, Typography } from '@material-ui/core'
import fan from 'features/customer-drill-down/icons/fan.svg'
import humidity from 'features/customer-drill-down/icons/humidification.svg'
import ventilation from 'features/customer-drill-down/icons/ventilation.svg'
import damper from 'features/customer-drill-down/icons/damper.svg'

import inputAirflow from 'features/customer-drill-down/icons/input-airflow.svg'
import inputOutdoor from 'features/customer-drill-down/icons/input-outdoor.svg'
import inputTemperature from 'features/customer-drill-down/icons/input-temperature.svg'
import inputHumidity from 'features/customer-drill-down/icons/input-humidity.svg'
import inputPm from 'features/customer-drill-down/icons/input-pm.svg'
import inputVoc from 'features/customer-drill-down/icons/input-voc.svg'
import { Measurement } from 'types';
import { displayUnit, findUnit, humanizeMeasurement, parseUnit } from './util'
import useConvertParticulateUnit from 'utils/hooks/useConvertParticulateUnit'

const findEquipSrc = (type) => {
    if (!type) return fan
    if (type.includes('air_handler')) return fan
    if (type.includes('ventilation')) return ventilation
    if (type.includes('humidifier')) return humidity
    if (type.includes('damper')) return damper
}

const findInputSrc = (measurement: Measurement | undefined) => {
    if (!measurement) return
    if (measurement.includes('airflow')) return inputAirflow
    if (measurement.includes('epa_aqi')) return inputOutdoor
    if (measurement.includes('temperature')) return inputTemperature
    if (measurement.includes('voc')) return inputVoc
    if (measurement.includes('pm25')) return inputPm
    if (measurement.includes('humidity') ||
        measurement.includes('dew_point')) return inputHumidity
}

function StaticAirflowInput({ input }) {
    const {
        start_set_point,
        type,
        rising,
        measurement,
    } = input

    let verb = rising ? 'rises above' : 'falls below'

    const unit = findUnit(measurement)
    const { convertedUnit, display } = useConvertParticulateUnit(measurement, unit)

    console.log('static airflow input',
        unit,
        convertedUnit,
        measurement,
    )

    return (
        <Box display='flex' gridGap='8px'>
            <img src={findInputSrc(measurement)} />
            <Typography variant='body1'>
                {humanizeMeasurement(type, measurement)} {verb} {display(start_set_point)} {parseUnit(convertedUnit)}
            </Typography>
        </Box>
    )

}

function StaticInput({ ruleId, inputId }) {
    const input = useRulesStore(selectInput(ruleId, inputId))
    if (!input) return null

    const {
        start_set_point,
        low_end_start_set_point,
        high_end_start_set_point,
        type,
        rising,
        measurement,
    } = input

    if (measurement === 'voc' || measurement === 'pm25') return <StaticAirflowInput input={input} />

    let verb = rising ? 'rises above' : 'falls below'
    if (input.type.includes('range')) verb = 'goes outside of range'

    const unit = findUnit(measurement)

    const { display, convertedUnit } = useConvertTemperatureUnits(input)
    const { convertedUnit: convertedParticulateUnit } = useConvertParticulateUnit(measurement, unit)

    return (
        <Box display='flex' gridGap='8px'>
            <img src={findInputSrc(measurement)} />
            <Typography variant='body1'>
                {humanizeMeasurement(type, measurement)} {verb} {display(start_set_point)} {low_end_start_set_point ? `${display(low_end_start_set_point)}  -  ${display(high_end_start_set_point)}` : null}{displayUnit(convertedUnit || convertedParticulateUnit || measurement)}
            </Typography>
        </Box>
    )
}

export {
    findEquipSrc,
    findInputSrc,
}

export default StaticInput
