import React from 'react'
import { Button, Typography } from "@material-ui/core"
import Stack from "ui/stack"
import Error from './error'
import ButtonSpinner from './button-spinner'

function Footer({
  error,
  touched,
  loading,
  success,
  handleSave,
  handleCancel,
}) {
  return (
    <Stack direction='row'>
      <Button
        disabled={loading}
        onClick={handleCancel}
      >{touched ? 'Cancel' : ''}</Button>
      <Stack spacing={1} direction='row'>
        {success && (
          <Typography variant='body2' color='primary'>
            {success}
          </Typography>
        )}
        {error && (
          <Typography variant='body2'>
            <Error>
              {error}
            </Error>
          </Typography>
        )}
        <Button
          disabled={!touched || loading || error}
          onClick={handleSave}
          size='small'
          variant='contained'
          color='primary'
        >
          {loading ?
            <ButtonSpinner /> :
            'Save'}
        </Button>
      </Stack>
    </Stack>

  )
}

export default Footer
