import * as React from 'react'
import { Box, Typography } from "@material-ui/core"
import useAdmin from 'utils/hooks/useAdmin'
import { usePageTitle } from 'utils/hooks/router'
import { useMixPanel } from 'features/analytics'
import useClasses from './equipment/useClasses'
import useDwellingStore, { selectContents } from 'stores/dwelling'
import controllerIcon from './icons/controller-icon.svg'
import monitorIcon from './icons/monitor-icon.svg'
import { theme } from 'theme'
import centralAirHandler from './icons/equip-air-handler.svg'
import outdoorAirDamper from './icons/equip-outdoor-damper.svg'
import humidifier from './icons/equip-humidifier.svg'
import ventilatingDehumidifier from './icons/equip-venti-dehum.svg'
import ventilatingDehumidifierFan from './icons/equip-venti-dehum-fan.svg'
import ventilatingDehumidifierCompressor from './icons/equip-venti-dehum-compressor.svg'
import dehumidifier from './icons/equip-dehumidifier.svg'
import mechanicalVentilation from './icons/equip-mech-venti.svg'
import { capitalize } from 'utils/string-utils'
import Muted from './equipment/muted'
import NewInstall from './equipment/install/new-install'
import useCurrentZone from 'utils/hooks/useCurrentZone'
import { selectFirstEquipmentAutomation, useAutomationsStore } from './equipment/store'

const humanizeDeviceType = (type: 'cac' | 'cam') => type === 'cac' ? 'Controller' : 'Monitor'
const src = (type: 'cac' | 'cam') => type === 'cac' ? controllerIcon : monitorIcon

const humanizeEquipType = (type?: string) => {
    if (!type) return ''
    return capitalize(type.split('_').join(' '))
}

const equipIcons = {
    centralAirHandler,
    outdoorAirDamper,
    humidifier,
    ventilatingDehumidifier,
    ventilatingDehumidifierFan,
    ventilatingDehumidifierCompressor,
    dehumidifier,
    mechanicalVentilation,
}

const equipSrc = (type?: string) => {
    if (!type) return ''
    return equipIcons[type.split('_').map((x, i) => i ? capitalize(x) : x).join('')]
}

function Equipment({ setZone, dwellingId }) {
    const classes = useClasses()
    const adminUser = useAdmin()
    const { mixpanel } = useMixPanel()
    const [newInstallOpen, setNewInstallOpen] = React.useState(false)
    const currentZone = useCurrentZone()
    const zoneBeforeInstallStarted = React.useRef(currentZone)
    const dwellingContents = useDwellingStore(selectContents(dwellingId))

    const equipmentAutomation = useAutomationsStore(selectFirstEquipmentAutomation(dwellingId, currentZone))
    const outputs = equipmentAutomation?.outputs

    console.log('automation', equipmentAutomation)
    console.log('outputs', outputs)

    // const installEnabled = useIsEnabled('install')

    usePageTitle(adminUser ? `Customer ${adminUser.first_name} - Equipment ` : 'Equipment')

    React.useEffect(() => {
        if (mixpanel) {
            mixpanel.track_pageview()
        }
    }, [mixpanel])

    // const onClickNewInstall = () => {
    //     setNewInstallOpen(true)
    // }

    const onClose = () => {
        setZone(zoneBeforeInstallStarted.current)
        setNewInstallOpen(false)
    }

    const serialNumber = deviceId =>
        dwellingContents?.devices.find(x => x.type === 'cac' && x.id === deviceId)?.plastic_serial_number || ''

    console.log('dwelin', dwellingContents?.devices)

    return (
        <>
            <div className={classes.root}>
                <Typography style={{ marginBottom: '24px' }} variant='h6'>
                    Equipment</Typography>
                <div className={classes.gridContainer}>
                    <div>
                        <Typography variant='subtitle1'>
                            HAVEN devices
                        </Typography>
                        <Box
                            bgcolor='white'
                            mt={2}
                            borderRadius='12px'
                            padding='24px'
                            gridGap='16px'
                        >
                            {dwellingContents?.devices?.length ? dwellingContents?.devices.map((x, index) => (
                                <Box
                                    key={x.id}
                                    border={`1px solid ${theme.palette.charcoalLightTint}`}
                                    borderRadius='11px'
                                    padding={2}
                                    display='grid'
                                    gridTemplateColumns='64px 1fr'
                                    marginTop={index ? 2 : 0}
                                >
                                    <img src={src(x.type)} />
                                    <div>
                                        <Typography variant='subtitle1'>
                                            {humanizeDeviceType(x.type)}
                                        </Typography>
                                        <Typography>
                                            <Muted>
                                                {x.plastic_serial_number}
                                            </Muted>
                                        </Typography>
                                    </div>
                                </Box>
                            )) : (<Muted>No devices found</Muted>)}
                        </Box>
                    </div>
                    <div>
                        <Typography variant='subtitle1'>
                            Equipment
                        </Typography>
                        <Box
                            bgcolor='white'
                            mt={2}
                            borderRadius='12px'
                            padding='24px'
                            gridGap='16px'
                        >
                            {outputs?.length ? outputs.filter(x => x.equipment).map((x, index) => (
                                <Box
                                    key={x.id}
                                    border={`1px solid ${theme.palette.charcoalLightTint}`}
                                    borderRadius='11px'
                                    padding={2}
                                    display='grid'
                                    alignItems='center'
                                    gridTemplateColumns='64px 1fr'
                                    marginTop={index ? 2 : 0}
                                >
                                    <img src={equipSrc(x?.equipment?.type)} />
                                    <div>
                                        <Typography variant='subtitle1'>
                                            {humanizeEquipType(x?.equipment?.type)}
                                        </Typography>
                                        <p style={{ margin: 0 }}>
                                            <Muted>
                                                {x?.equipment?.brand} {x?.equipment?.model} {` `}
                                            </Muted>
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            <Muted>
                                                Connected {serialNumber(x.device_id)} terminal {x.terminal}
                                            </Muted>
                                        </p>
                                    </div>
                                </Box>
                            )) : <Muted>No equipment found</Muted>}
                        </Box>
                    </div>
                    <div>
                        {/* <Box
                            display='flex'
                            gridGap='16px'
                        >
                            <Typography variant='subtitle1'>
                                Installations
                            </Typography>
                            {installEnabled && (
                                <Button onClick={onClickNewInstall}>New +</Button>
                            )}
                        </Box> */}
                        {/* <Box
                            bgcolor='white'
                            mt={2}
                            borderRadius='12px'
                            padding='24px'
                            gridGap='16px'
                        > */}
                        {/* @ts-ignore */}
                        {/* {dwellingContents?.installations?.length ? dwellingContents?.installations.map((x, index) => (
                                <Box
                                    key={x.id}
                                    border={`1px solid ${theme.palette.charcoalLightTint}`}
                                    borderRadius='11px'
                                    padding={2}
                                    display='grid'
                                    gridTemplateColumns='64px 1fr'
                                    marginTop={index ? 2 : 0}
                                >
                                    <img src={equipSrc(x.type)} />
                                    <div>
                                        <Typography variant='subtitle1'>
                                            {humanizeEquipType(x.type)}
                                        </Typography>
                                        <Typography>
                                            {x.name}
                                        </Typography>
                                    </div>
                                </Box>
                            )) : <Muted>No installations found</Muted>} */}
                        {/* </Box> */}
                    </div>
                </div>
            </div>
            <NewInstall
                open={newInstallOpen}
                setZone={setZone}
                onClose={onClose}
            />
        </>
    )
}

export default Equipment
