import * as React from 'react'
import controllerIcon from '../../icons/controller-icon.svg'
import controllerImg from '../../icons/controller.png'
import { InnerCard } from './new-install'
import {
    Box,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import Muted from '../muted'
import { Cancel } from '@material-ui/icons'
import useStore, {
    defaultControllers,
    selectMonitorEquipment,
    selectMonitorBrand,
    selectRelays,
    setRelay,
    setRelays,
    setStepData,
    selectMonitorModel,
} from 'stores/install'
import DuctInformation from './duct-information'
import NormallyClosed from '../../icons/damper-normally-closed.svg'
import PoweredOpen from '../../icons/damper-powered-open.svg'
import NormallyOpen from '../../icons/damper-normally-open.svg'
import PoweredClosed from '../../icons/damper-powered-closed.svg'
import { brands } from './data'
import Combobox from 'ui/combobox'
import AddNew from './add-new'

const controllerEquipments = [
    {
        title: 'Air handler blower fan',
        value: 'central_air_handler',
    },
    {
        title: 'Mechanical ventilation fan',
        value: 'mechanical_ventilation',
    },
    {
        title: 'Humidifier',
        value: 'humidifier',
    },
    {
        title: 'Dehumidifier',
        value: 'dehumidifier',
    },
    {
        title: 'Ventilating dehumidifier compressor',
        value: 'ventilating_dehumidifier_compressor',
    },
    {
        title: 'Ventilating dehumidifier fan',
        value: 'ventilating_dehumidifier_fan',
    },
    {
        title: 'Outdoor damper',
        value: 'outdoor_air_damper',
    },
]

const formattedBrands = brands.map(x => ({ title: x }))

const isBlower = (equipName?: string) => equipName &&
    (equipName.includes('air_handler') ||
        equipName.includes('dehumidifier_fan') ||
        equipName.includes('mechanical_ventilation'))

type Relays = 'relay1' | 'relay2'

const boolify = value => value === 'true' ? true : false

function DamperDetails({ normallyClosed, setter }) {
    // damper equipment details
    const handleChange = event => setter(boolify(event.target.value))

    return (
        <>
            <Box mt={4}>
                <p style={{ marginBottom: 0 }}>Damper is normally</p>
                <FormControl>
                    <RadioGroup
                        style={{ flexDirection: 'row' }}
                        name='normally_closed'
                        value={`${normallyClosed}`}
                        onChange={handleChange}
                        aria-label='normally_closed'>
                        <FormControlLabel defaultChecked style={{ marginRight: '24p' }} value='true' label='Closed' control={<Radio />} />
                        <FormControlLabel value='false' label='Open' control={<Radio />} />
                    </RadioGroup>
                </FormControl>
            </Box>
            {normallyClosed ? (
                <Box
                    bgcolor='#F7FBFD'
                    alignItems='center'
                    justifyContent='space-between'
                    display='flex'
                    borderRadius='5px'
                    p={2}
                >
                    <Box width='50%'>
                        <p style={{ fontWeight: 'bold' }}>Normally closed</p>
                        <p>Damper needs power to open</p>
                    </Box>
                    <Box
                        width='50%'
                        alignItems='center'
                        justifyContent='space-between'
                        display='flex'
                    >
                        <Box textAlign='center'>
                            <img src={NormallyClosed} />
                            <p style={{ margin: 0 }}>unpowered</p>
                        </Box>
                        <Box textAlign='center'>
                            <img src={PoweredOpen} />
                            <p style={{ margin: 0 }}>powered</p>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Box
                    bgcolor='#F7FBFD'
                    alignItems='center'
                    justifyContent='space-between'
                    display='flex'
                    borderRadius='5px'
                    p={2}
                >
                    <Box width='50%'>
                        <p style={{ fontWeight: 'bold' }}>Normally open</p>
                        <p>Damper needs power to close</p>
                    </Box>
                    <Box
                        width='50%'
                        alignItems='center'
                        justifyContent='space-between'
                        display='flex'
                    >
                        <Box textAlign='center'>
                            <img src={NormallyOpen} />
                            <p style={{ margin: 0 }}>unpowered</p>
                        </Box>
                        <Box textAlign='center'>
                            <img src={PoweredClosed} />
                            <p style={{ margin: 0 }}>powered</p>
                        </Box>
                    </Box>
                </Box>
            )}
        </>
    )
}

function NewController({ controller }: { controller: 0 | 1 }) {
    const [open, setOpen] = React.useState(false)
    const relays = useStore(selectRelays(controller))
    const [readOnly, setReadOnly] = React.useState({ relay1: false, relay2: false })
    const brand1Ref = React.useRef<HTMLInputElement>(null)
    const brand2Ref = React.useRef<HTMLInputElement>(null)

    const monitorEquipment = useStore(selectMonitorEquipment)
    const monitorBrand = useStore(selectMonitorBrand)
    const monitorModel = useStore(selectMonitorModel)

    // const [errors, setErrors] = React.useState({ brand1: false, brand2: false })

    const setRelayData = setRelay(controller)
    const hasSelectedEquipment = (relay: Relays) => relays[relay].equipment && relays[relay].equipment !== 'Select...'
    const hasAnySelectedEquipment = hasSelectedEquipment('relay1') || hasSelectedEquipment('relay2')
    const showAddDuct = (relay: Relays) => hasSelectedEquipment(relay) && isBlower(relays[relay].equipment)
    const isOutdoorDamper = (relay: Relays) => relays[relay].equipment === 'outdoor_air_damper'

    React.useEffect(() => {
        setOpen(hasAnySelectedEquipment)
    }, [hasAnySelectedEquipment])

    React.useEffect(() => {
        // if an equipment is a ventilating dehumidifier
        // and a user is using both components of it we
        // can't allow a user to name it twice
        if (monitorEquipment !== 'ventilating_dehumidifier_fan') return

        const relay1IsVentilatingDehumidifierCompressor = relays.relay1.equipment === 'ventilating_dehumidifier_compressor'
        const relay2IsVentilatingDehumidifierCompressor = relays.relay2.equipment === 'ventilating_dehumidifier_compressor'

        setReadOnly({
            relay1: relay1IsVentilatingDehumidifierCompressor,
            relay2: relay2IsVentilatingDehumidifierCompressor,
        })

        if (relay1IsVentilatingDehumidifierCompressor) {
            setRelayData('relay1')({
                brand: monitorBrand,
                model: monitorModel,
            })
        }
        if (relay2IsVentilatingDehumidifierCompressor) {
            setRelayData('relay2')({
                brand: monitorBrand,
                model: monitorModel,
            })
        }
    }, [
        monitorEquipment,
        monitorBrand,
        relays.relay1.equipment,
        relays.relay2.equipment,
    ])

    React.useEffect(() => {
        if (hasSelectedEquipment('relay1') && brand1Ref.current) brand1Ref.current.focus()
        if (hasSelectedEquipment('relay2') && brand2Ref.current) brand2Ref.current.focus()
    }, [
        hasSelectedEquipment('relay1'),
        hasSelectedEquipment('relay2'),
        brand1Ref.current,
        brand2Ref.current,
    ])

    const onCancel = () => {
        setOpen(false)
        setRelays(controller)(defaultControllers)
    }

    return (
        <>
            {!open ? (
                <AddNew onClick={() => setOpen(true)}>
                    Add controller
                </AddNew>
            ) : (
                <InnerCard p={2}>
                    <Box
                        display='flex'
                        alignItems='center'
                        width='100%'
                        gridGap='16px'
                    >
                        <img src={controllerIcon} />
                        <Box flex='1'>
                            <Typography style={{ fontWeight: 'normal' }}>HAVEN controller</Typography>
                            <Muted>New</Muted>
                        </Box>
                        <Cancel
                            onClick={onCancel}
                            style={{ cursor: 'pointer' }}
                        />
                    </Box>
                    <Box mt={3}>
                        <Typography variant='subtitle1'>
                            Relay 1
                        </Typography>
                        <Box position='relative'>
                            <img
                                style={{ width: '100%' }}
                                src={controllerImg}
                            />
                            <Box
                                border='4px solid #39DD7B'
                                borderRadius='99px'
                                bgcolor='transparent'
                                width='40px'
                                height='40px'
                                position='absolute'
                                right='111px'
                                bottom='8px'
                            />
                        </Box>
                        <Box mt={2}>
                            <FormControl
                                size='small'
                                variant='outlined'
                                fullWidth
                            >
                                <InputLabel
                                    htmlFor='relay1'
                                >Relay 1 equipment</InputLabel>
                                <Select
                                    id='relay1'
                                    name='relay1'
                                    label='Relay 1 equipment'
                                    value={relays.relay1.equipment}
                                    onChange={e => setRelayData('relay1')({ equipment: e.target.value as string })}
                                >
                                    <MenuItem disabled value='Select...'>
                                        Select...
                                    </MenuItem>
                                    {controllerEquipments.map(x => (
                                        <MenuItem key={x.value} value={x.value}>
                                            {x.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    {hasSelectedEquipment('relay1') && (
                        <>
                            <Box mt={2}>
                                <Combobox
                                    ref={brand1Ref}
                                    value={relays.relay1.brand}
                                    options={formattedBrands}
                                    onChange={brand => setRelayData('relay1')({ brand })}
                                    label='Equipment brand'
                                />
                            </Box>
                            <Box mt={2}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    value={relays.relay1.model}
                                    onChange={e => setRelayData('relay1')({ model: e.target.value })}
                                    label='Equipment model'
                                    inputProps={{
                                        maxLength: 99,
                                    }}
                                />
                            </Box>
                        </>
                    )}
                    {showAddDuct('relay1') && (
                        // @ts-ignore
                        <DuctInformation stateKey={`c${controller + 1}r1`} />
                    )}
                    {isOutdoorDamper('relay1') && (
                        <DamperDetails
                            normallyClosed={relays.relay1.normally_closed}
                            setter={normally_closed =>
                                setRelayData('relay1')({ normally_closed })
                            }
                        />
                    )}
                    <Box mt={4}>
                        <Typography variant='subtitle1'>
                            Relay 2
                        </Typography>
                        <Box position='relative'>
                            <img
                                style={{ width: '100%' }}
                                src={controllerImg}
                            />
                            <Box
                                border='4px solid #39DD7B'
                                borderRadius='99px'
                                bgcolor='transparent'
                                width='40px'
                                height='40px'
                                position='absolute'
                                right='78px'
                                bottom='8px'
                            />
                        </Box>
                        <Box mt={2}>
                            <FormControl
                                size='small'
                                variant='outlined'
                                fullWidth
                            >
                                <InputLabel
                                    htmlFor='relay2'
                                >Relay 2 equipment</InputLabel>
                                <Select
                                    id='relay2'
                                    name='relay2'
                                    label='Relay 2 equipment'
                                    value={relays.relay2.equipment}
                                    onChange={e => setRelayData('relay2')({ equipment: e.target.value as string })}
                                >
                                    <MenuItem disabled value='Select...'>
                                        Select...
                                    </MenuItem>
                                    {controllerEquipments.map(x => (
                                        <MenuItem key={x.value} value={x.value}>
                                            {x.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    {hasSelectedEquipment('relay2') && (
                        <>
                            <Box mt={2}>
                                <Combobox
                                    ref={brand2Ref}
                                    value={relays.relay2.brand}
                                    options={formattedBrands}
                                    disabled={readOnly.relay2}
                                    onChange={brand => setRelayData('relay2')({ brand })}
                                    label='Equipment brand'
                                />
                            </Box>
                            <Box mt={2}>
                                <TextField
                                    variant='outlined'
                                    size='small'
                                    fullWidth
                                    value={relays.relay2.model}
                                    onChange={e => setRelayData('relay2')({ model: e.target.value })}
                                    label='Equipment model'
                                    inputProps={{
                                        maxLength: 99,
                                    }}
                                />
                            </Box>
                        </>
                    )}
                    {showAddDuct('relay2') && (
                        // @ts-ignore
                        <DuctInformation stateKey={`c${controller + 1}r2`} />
                    )}
                    {isOutdoorDamper('relay2') && (
                        <DamperDetails
                            normallyClosed={relays.relay2.normally_closed}
                            setter={normally_closed =>
                                setRelayData('relay2')({ normally_closed })
                            }
                        />
                    )}
                </InnerCard>
            )}
        </>
    )
}

export { isBlower, formattedBrands, }
export default NewController
