import React, { useEffect } from 'react'
import {
  Button,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core"
import { useState } from "react"
import httpService from 'state-mngt/services/data/http-service'
import {
  selectAutomation,
  selectEquipments,
  selectRule,
  setAutomation,
  toDict,
  updateRule,
  useAutomationsStore,
  useEquipmentStore,
  useRulesStore,
} from './store'
import OverrideModal from './overrideModal'
import differenceInHours from "date-fns/differenceInHours"
import differenceInMinutes from "date-fns/differenceInMinutes"
import differenceInSeconds from "date-fns/differenceInSeconds"
import differenceInDays from "date-fns/differenceInDays"
import Stack from 'ui/stack'
import Muted from './muted'
import { Automation } from 'types'
import ButtonSpinner from './button-spinner'

const useStyles = makeStyles(theme => createStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    borderRadius: '11px',
  },
  modalContent: {
    padding: '8px 16px',
    '> *': {
      marginTop: '32px',
    },
  },
  modalFooter: {
    border: `1px solid ${theme.palette.charcoalLightTint}`,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  controls: {
    marginTop: '16px',
  },
}))

const aboveZero = x => Math.round(Math.max(0, x))

const _format = (end, start) => {
  const days = differenceInDays(end, start)
  const hours = aboveZero(differenceInHours(end, start) % 24)
  const minutes = aboveZero(differenceInMinutes(end, start) % 60)
  const seconds = aboveZero(differenceInSeconds(end, start) % 60)

  return `${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s ` : ''}`
}

const useTime = (time, cancel) => {
  const [duration, setDuration] = useState<string | null>(null)

  useEffect(() => {
    if (!time) return

    const interval = setInterval(() => {
      if (time - Date.now() <= 0) {
        clearInterval(interval)
        cancel()
        return setDuration(null)
      }
      setDuration(_format(time, Date.now()))
    }, 1000)

    return () => clearInterval(interval)
  }, [time])

  return duration
}

const now = new Date()

function Override({
  ruleId,
}) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const rule = useRulesStore(selectRule(ruleId))
  const automation = useAutomationsStore(selectAutomation(rule?.automationId))
  const outputs = automation.outputs
  // const equipments = useEquipmentStore(selectEquipments(rule?.outputs.map(output => output.equipment_id)))

  if (!rule) return null

  const { override_timeout, override_on } = rule.trigger
  const ruleIsTriggered = rule.inputs.every(input => input.currently_triggered)

  // @ts-ignore
  const on = Boolean(override_timeout) && (new Date(override_timeout) > now)

  const save = async (data: Partial<Automation>) => {
    try {
      await httpService.post(`/automation/${rule.automationId}`, data)
    } catch (e) {
      console.error(e)
    }
  }

  const updateOutput = (currently_triggered: boolean) => {
    setAutomation(rule.automationId, { ...automation, outputs: outputs.map(x => ({ ...x, currently_triggered })) })
  }

  // save and then update state
  const saveAndUpdate = async (update: { override_timeout: string | null, override_on: boolean }) => {
    const data = {
      ...automation,
      rules: automation.rules.map(rule => rule.id === ruleId ? ({
        ...rule,
        ...update,
      }) : rule),
    }

    await save(data)

    updateRule(ruleId)({
      data: { trigger: { ...rule.trigger, ...update } },
      syncTempRule: true,
    })

    updateOutput(update.override_on)
  }

  const override = async (override_timeout) => {
    const update = {
      override_on: !ruleIsTriggered,
      override_timeout,
    }

    setLoading(true)
    await saveAndUpdate(update)
    setOpen(false)
    setLoading(false)
  }

  const cancel = async () => {
    const update = {
      override_on: ruleIsTriggered,
      override_timeout: null,
    }

    setLoading(true)
    await saveAndUpdate(update)
    setOpen(false)
    setLoading(false)
  }

  const timer = useTime(
    override_timeout ? new Date(override_timeout).getTime() : null,
    cancel,
  )

  const state = override_on ? 'on' : 'off'

  if (on) return (
    <Stack
      spacing={1}
      direction='row'
    >
      <Typography variant='caption'><Muted>Equipment is {state} for {timer}</Muted></Typography>
      <Button
        className={classes.cancelButton}
        disabled={loading}
        onClick={cancel}
      >{loading ? <ButtonSpinner /> : <>Cancel</>}</Button>
    </Stack>
  )

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={loading}
        color='primary'>
        Override
      </Button>
      <OverrideModal
        isOpen={open}
        loading={loading}
        equipmentIsTriggered={override_on && override_timeout}
        onClose={() => setOpen(false)}
        override={override}
      />
    </>
  )
}

export default Override
