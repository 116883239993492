import {
    Box,
    Button,
    Grid,
    Typography
} from "@material-ui/core"
import { Add } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import {
    Interlock
} from 'types'
import AutomationRuleCard from './automation-rule-card'
import {
    selectAllEquipmentAutomationOutputs,
    selectEquipmentAutomations,
    selectEquipmentByZone,
    selectFirstEquipmentAutomation,
    selectInterlocks,
    setAutomation,
    useAutomationsStore,
    useEquipmentStore,
} from "./store"
import useClasses from './useClasses'
import { tempId } from './util'
import { useMixPanel } from "features/analytics"
import SingleInterlock from "./install/single-interlock"
import { isBlower } from "./install/new-controller"
import useCurrentZone from "utils/hooks/useCurrentZone"
import Footer from "./footer"
import Muted from "./muted"
import httpService from "state-mngt/services/data/http-service"
import EmptyState from "./empty-state"
import AddNew from "./install/add-new"

const NewSchedule = ({
    automation,
    createNewSchedule,
}) => {
    if (!automation) return null

    return (
        <Grid
            xs={12}
            md={6}
            lg={4}
            item>
            <AutomationRuleCard style={{
                display: 'flex', justifyContent: 'center', paddingTop: 16,
            }}>
                <Button
                    onClick={createNewSchedule}
                    startIcon={<Add />}
                >New schedule</Button>
            </AutomationRuleCard>
        </Grid>
    )
}

const defaultInterlock = (primary_output, secondary_output): Interlock => ({
    primary_output,
    secondary_output,
    automation_id: 0,
    enabled: true,
    id: tempId(),
})

const Interlocks = ({ dwellingId }: { dwellingId: number }) => {
    const classes = useClasses()
    const zone = useCurrentZone()

    const equipmentAutomation = useAutomationsStore(selectFirstEquipmentAutomation(dwellingId, zone))
    const interlocks = (equipmentAutomation?.interlocks || []).filter(x => x.enabled)
    const outputs = equipmentAutomation?.outputs || []

    const [localInterlocks, setLocalInterlocks] = useState(interlocks)
    const [loading, setLoading] = useState(false)
    const [touched, setTouched] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    console.log('equipemnt automation', equipmentAutomation)

    const equipments = useEquipmentStore(selectEquipmentByZone({ dwellingId, zone }))
    const equipmentsWithOutputs = outputs.map(x => ({ ...x, ...equipments.find(y => y.id === x.equipment_id), id: x.id }))
    const { mixpanel } = useMixPanel()

    useEffect(() => {
        setTouched(JSON.stringify(localInterlocks) !== JSON.stringify(interlocks))
    }, [
        JSON.stringify(localInterlocks),
        JSON.stringify(interlocks),
    ])

    const ventilatingDehumidifierFan = equipmentsWithOutputs
        .find(x =>
            x.type?.includes('ventilating_dehumidifier') &&
            x.equipment_component === 1)

    const ventilatingDehumidifierCompressor = equipmentsWithOutputs
        .find(x =>
            x.type?.includes('ventilating_dehumidifier') &&
            x.equipment_component === 0)

    const blowers = [
        ...equipmentsWithOutputs.filter(x => (x && isBlower(x.type))),
        ...(ventilatingDehumidifierFan ? [{ ...ventilatingDehumidifierFan, type: 'ventilating_dehumidifier_fan' }] : []),
    ]

    const nonBlowers = [
        ...equipmentsWithOutputs.filter(x => !(blowers.map(y => y.id).includes(x.id))),
        ...(ventilatingDehumidifierCompressor ? [{ ...ventilatingDehumidifierCompressor, type: 'ventilation_dehumidifer_compressor' }] : []),
    ].filter(x => x.type !== 'ventilating_dehumidifier')

    // non blowers can only be used once
    const usedNonBlowers = nonBlowers.filter(x => interlocks.find(interlock => {
        return interlock.primary_output === x.id
    }))

    const createNewInterlock = () => {
        setLocalInterlocks(prev => ([...prev, defaultInterlock(nonBlowers[0].id, blowers[0].id)]))
    }

    const setNonBlower = index => primary_output => {
        setLocalInterlocks(prev => prev.map((x, i) => {
            if (index === i) {
                return {
                    ...x,
                    primary_output,
                }
            }
            return x
        }))
    }

    const setBlower = index => secondary_output => {
        setLocalInterlocks(prev => prev.map((x, i) => {
            if (index === i) {
                return {
                    ...x,
                    secondary_output,
                }
            }
            return x
        }))
    }

    React.useEffect(() => {
        if (mixpanel) {
            mixpanel.track_pageview()
        }
    }, [mixpanel])

    const handleSave = async () => {
        if (!equipmentAutomation) return
        setLoading(true)
        const updatedAutomation = { ...equipmentAutomation, interlocks: localInterlocks }
        try {
            await httpService.post(`/automation/${equipmentAutomation.id}`, updatedAutomation)
            setAutomation(equipmentAutomation.id, updatedAutomation)
        } catch (e) {
            console.error(e)
            // @ts-ignore
            setError(e.message || "Couldn't save")
        }
        setLoading(false)
    }

    const handleCancel = () => {
        setLocalInterlocks(interlocks)
    }

    const handleDelete = index => async () => {
        if (!equipmentAutomation) return
        setLoading(true)
        const updatedInterlocks = localInterlocks.map((x, i) => ({ ...x, ...(i === index ? { enabled: false } : {}) }))
        const updatedAutomation = { ...equipmentAutomation, interlocks: updatedInterlocks }

        try {
            await httpService.post(`/automation/${equipmentAutomation.id}`, updatedAutomation)
            setLocalInterlocks(updatedInterlocks.filter(x => x.enabled))
            setAutomation(equipmentAutomation.id, updatedAutomation)
        } catch (e) {
            // @ts-ignore
            console.error(e?.message)
        }

        setLoading(false)
        // deleting an interlock removes it from the local state, the automation state, and then saves the automation
    }

    const hasError = (index) =>
        !localInterlocks[index].primary_output ||
        !localInterlocks[index].secondary_output

    const renderFooter = index => () =>
        <Box mt={2}>
            <Footer
                error={hasError(index)}
                loading={loading}
                handleSave={handleSave}
                handleCancel={handleCancel}
                success={success}
                touched={touched}
            />
        </Box>

    return (
        <div className={classes.root}>
            <Typography
                style={{ marginBottom: '24px' }}
                variant='h6'>
                Interlocks
            </Typography>
            <Typography variant='subtitle1'>
                Interlocking ensures non-blower equipment is never activated without a blower
            </Typography>
            <Box
                mt={4}
                display='grid'
                gridGap='16px'
                width='100%'
                gridTemplateColumns={[
                    '1fr',
                    '1fr 1fr',
                    '400px 400px 400px',
                ]}
                alignItems='start'
            >
                {localInterlocks.map(({ primary_output, secondary_output }, index) => (
                    <SingleInterlock
                        key={`${primary_output}${secondary_output}`}
                        nonBlowerId={primary_output}
                        blowerId={secondary_output}
                        setBlowerId={setBlower(index)}
                        setNonBlowerId={setNonBlower(index)}
                        blowers={blowers}
                        nonBlowers={nonBlowers}
                        renderFooter={renderFooter(index)}
                        loading={loading}
                        handleDelete={handleDelete(index)}
                    />
                ))}
                {usedNonBlowers.length !== nonBlowers.length ? (
                    <AddNew onClick={createNewInterlock}>
                        New interlock
                    </AddNew>
                ) : <EmptyState>No equipment to interlock</EmptyState>}
            </Box>
        </div>
    )

    // const classes = useClasses()
    // const [searchParams] = useDecodedSearchParams()
    // const selectedZone = searchParams.get(HISTORY_QUERY_KEYS.zone) || '';

    // const [isDialogOpen, setIsDialogOpen] = useState(false)
    // const rules = useRulesStore(selectScheduleRules(dwellingId, selectedZone))

    // const automations = useAutomationsStore(selectEquipmentAutomations(dwellingId, selectedZone))
    // const outputs = rules.flatMap(x => x.outputs)

    // // assuming there is one cac automation per zone
    // const automation = automations?.[0]

    // const equipmentIds = dedupe(outputs?.map(output => output.equipment)) || []
    // const equipment = useEquipmentStore(selectEquipments(equipmentIds))
    // const processedOutputs = outputs.map(x => typeof x.equipment_component === 'number' ?
    //     ({
    //         ...x,
    //         equipment_id: Number(`${x.equipment_id}${x.equipment_component}`),
    //     }) : x)

    // const processedEquipment: Equipment[] = Object
    //     .values(equipment)
    //     .filter(x => x.type !== 'humidifier')
    //     .reduce((prev, curr) => {
    //         if (curr.type === 'ventilating_dehumidifier') {
    //             return [
    //                 ...prev,
    //                 {
    //                     ...curr,
    //                     name: 'Fan',
    //                     id: Number(`${curr.id}0`),
    //                 },
    //                 {
    //                     ...curr,
    //                     name: 'Compressor',
    //                     id: Number(`${curr.id}1`),
    //                 }]
    //         }
    //         return [...prev, curr]
    //     }, [] as Equipment[])

    // const loading = useLoadingStore()

    // // list of equipments that've already been selected
    // const [selected, setSelected] = useState<{ [key: number]: number[] }>({})

    // const setSelectedEquipment = (ruleId: number) => (equipment: Equipment[]) => {
    //     setSelected(prev => ({ ...prev, [ruleId]: equipment.map(x => x.id) }))
    // }

    // const getAvailableEquipment = (ruleId: number) => {
    //     const selectedInOtherRules = Object.keys(selected).filter(key => key !== `${ruleId}`).flatMap(x => selected[x])

    //     const awailable = processedEquipment
    //         .filter(equip => !selectedInOtherRules.includes(equip.id))

    //     return awailable
    // }

    // const hasAvailableEquipment = Boolean(processedEquipment
    //     .filter(x => !Object.values(selected).flatMap(x => x).includes(x.id)).length)

    // const updateAutomation = (update: Partial<Automation>) => {
    //     useAutomationsStore.setState(prev => ({
    //         ...prev,
    //         ...(automation ? {
    //             [automation.id]: {
    //                 ...(prev[automation.id] || {}),
    //                 ...update,
    //             },
    //         } : {}),
    //     }))
    // }

    // const disableRule = async (ruleId: number, save: boolean) => {
    //     const rule = rules.find(x => x.id === ruleId)
    //     if (!rule) return console.warn('[disableRule] ', 'no rule found')
    //     if (!automation) return console.warn('[disableRule] ', 'no automation found')

    //     const updatedAutomation = {
    //         ...(automation || {}),
    //         rules: automation.rules
    //             .map(x => x.id === ruleId ? { ...x, enabled: false } : x),
    //     }

    //     useAutomationsStore.setState(prev => ({
    //         ...prev,
    //         [automation.id]: updatedAutomation,
    //     }))

    //     if (save) await httpService.post(`/automation/${automation.id}`, updatedAutomation)
    // }

    // const deleteRuleAndSaveAutomation = async (ruleId) => {
    //     await disableRule(ruleId, ruleId > 0) // negative ID means it's client-side and not yet saved so no need to persist
    //     const equipToRemove = processedOutputs.map(output => output.equipment_id) || []
    //     setSelected(prev => ({
    //         ...prev,
    //         [ruleId]: prev[ruleId].filter(x => !equipToRemove.includes(x)), // remove the equipment
    //     }))
    // }

    // const handleDelete = async (ruleId) => {
    //     if (rules.length <= 1) return setIsDialogOpen(true)
    //     await deleteRuleAndSaveAutomation(ruleId)
    // }

    // const onClickDialogYes = (ruleId) => () => {
    //     setIsDialogOpen(false)
    //     deleteRuleAndSaveAutomation(ruleId)
    // }

    // const onClickDialogCancel = () => {
    //     setIsDialogOpen(false)
    // }

    // // creates a new fake rule
    // const createNewSchedule = () => {
    //     const inputId = tempId()
    //     const outputId = tempId()
    //     const triggerId = tempId()

    //     const trigger: AutomationTrigger = {
    //         id: triggerId,
    //         enabled: true,
    //         filtration: false,
    //         ventilation: false,
    //         humidity: false,
    //         inputs: [inputId],
    //         outputs: [outputId],
    //         override_on: false,
    //         override_timeout: null,
    //     }

    //     const input: Partial<AutomationSchedule> = {
    //         id: inputId,
    //         type: 'schedule',
    //         currently_triggered: false,
    //         weekday_awake_interval: 'on_10_off_20',
    //         weekday_sleep_interval: 'on_10_off_20',
    //         weekend_awake_interval: 'on_10_off_20',
    //         weekend_sleep_interval: 'on_10_off_20',
    //         weekend_start_time: '08:00:00',
    //         weekend_stop_time: '22:00:00',
    //         weekday_start_time: '08:00:00',
    //         weekday_stop_time: '22:00:00',
    //     }

    //     const update = {
    //         inputs: [...(automation?.inputs || []), input],
    //         rules: [...(automation?.rules || []), trigger],
    //         dwellingId,
    //     }

    //     // @ts-ignore - schedule has a different input type
    //     updateAutomation(update)
    // }

    // if (loading) return <Loading />

    // console.log('chedule rules', rules)

    // return (
    //     <div className={classes.root}>
    //         <Typography style={{ marginBottom: '24px' }} variant='h6'>
    //             Interlocks
    //         </Typography>
    //         <div className={classes.gridContainer}>
    //             {rules.map((rule) => (
    //                 <Fragment key={rule.id}>
    //                     <Schedule
    //                         rule={rule}
    //                         processedOutputs={processedOutputs}
    //                         equipment={processedEquipment}
    //                         availableEquipment={getAvailableEquipment(rule.id)}
    //                         setSelectedEquipment={setSelectedEquipment(rule.id)}
    //                         handleDelete={handleDelete}
    //                     />
    //                     <DeleteScheduleDialog
    //                         onClickYes={onClickDialogYes(rule.id)}
    //                         onClickCancel={onClickDialogCancel}
    //                         isOpen={Boolean(isDialogOpen)}
    //                     />
    //                 </Fragment>
    //             ))}
    //             {hasAvailableEquipment ? (
    //                 <NewSchedule
    //                     automation={automation}
    //                     createNewSchedule={createNewSchedule}
    //                 />
    //             ) : (
    //                 <AutomationRuleCard style={{ display: 'flex', justifyContent: 'center', paddingTop: 16 }}>
    //                     <Stack align='center'>
    //                         <p style={{ color: 'rgba(0, 0, 0, 0.26)', textAlign: 'center' }}>No available equipment</p>
    //                     </Stack>
    //                 </AutomationRuleCard>
    //             )}
    //         </div>
    //     </div>
    // )
}

export { defaultInterlock }
export default Interlocks
