import React, { useState } from 'react'
import {
  persistTemporaryRule,
  resetTemporaryRule,
  selectEquipments,
  selectOutputsByRule,
  selectRule,
  useEquipmentStore,
  useRulesStore,
} from './store'
import Override from './override'
import AutomationInputControl from './input'
import RuleTitle from './ruleTitle'
import { Box, Typography } from '@material-ui/core'
import Stack from 'ui/stack'
import { AutomationRuleGroup } from 'types'
import Dot from './dot'
import AutomationRuleCard from './automation-rule-card'
import InnerCard from './inner-card'
import Footer from './footer'
import useIsTouched from './useIsTouched'
import Rule from './rule'
import { useResetAfterTimeout } from './useRuleState'
import useSaveTemporaryRuleToAutomation from './useSaveTemporaryRuleToAutomation'
import StaticRule from './static-rule'
import StaticRuleWithNesting from './static-rule-with-nesting'
import Error from './error'
import { humanize } from 'utils/string-utils'
import { findEquipSrc } from './static-input'

const useEquipmentsByRule = (ruleId) => {
  const outputs = useRulesStore(selectOutputsByRule(ruleId))
  const equipments = Object.values(useEquipmentStore(selectEquipments(outputs?.map(x => x.equipment_id) || [])) || {})
  return outputs.map(x => ({ ...x, equipment: equipments.find(y => y.id === x.equipment_id) }))
}

const RuleGroup = ({ ruleGroup }: { ruleGroup: AutomationRuleGroup }) => {
  const rootRuleId = ruleGroup.rules[0]?.id
  const nestedRuleId = ruleGroup.rules[1]?.id

  const rootRule = useRulesStore(selectRule(rootRuleId))
  const nestedRule = useRulesStore(selectRule(nestedRuleId))

  // const rootEquipment = useEquipmentsByRule(rootRule?.id)
  const rootOutputs = useRulesStore(selectOutputsByRule(rootRule.id))
  const nestedOutputs = nestedRule?.outputs.filter(x => !ruleGroup.rules[0]?.outputs.includes(x.id)) || []
  // const nestedEquipment = useEquipmentStore(selectEquipments(nestedOutputs.map(x => x.equipment_id)))

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const [editing, setEditing] = useState(false)

  useResetAfterTimeout([success, error], [setSuccess, setError])

  const rootTouchd = useIsTouched(rootRuleId)
  const nestedTouched = useIsTouched(nestedRuleId)

  const saveRootAutomation = useSaveTemporaryRuleToAutomation(rootRule.automationId)
  const saveNestedAutomation = useSaveTemporaryRuleToAutomation(nestedRule?.automationId)

  const handleCancel = () => {
    setEditing(false)
    resetTemporaryRule(rootRuleId)
    resetTemporaryRule(nestedRuleId)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await Promise.all([
        saveRootAutomation(),
        saveNestedAutomation(),
      ])
      persistTemporaryRule(rootRuleId)
      persistTemporaryRule(nestedRuleId)
      setSuccess('Saved ✓')
      setEditing(false)
    } catch (e) {
      console.error(e)
      setError('Couldn\'t save')
    }
    setLoading(false)
  }

  const renderFeedback = () => {
    if (error) return <p><Error>{error}</Error></p>
    if (success) return <p>{success}</p>
    return null
  }

  const touched = rootTouchd || nestedTouched

  if (!nestedRuleId) {
    if (!editing) return (
      <StaticRule
        ruleId={rootRule.id}
        renderFeedback={renderFeedback}
        handleClickEdit={() => setEditing(true)} />
    )
    return (
      <Rule
        setEditing={setEditing}
        ruleId={rootRule.id}
      />
    )
  }

  if (!editing) return <StaticRuleWithNesting ruleId={rootRule.id} handleClickEdit={() => setEditing(true)} />

  return (
    <AutomationRuleCard>
      <Stack spacing={4}>
        <Stack spacing={1}>
          <Stack direction='row'>
            <RuleTitle>
              Activate
            </RuleTitle>
            <Override
              ruleId={rootRule.id}
            />
          </Stack>
          <InnerCard>
            <Stack divider>
              {Object.values(rootOutputs.filter(x => x.equipment) || {})
                .map((x, i) => (
                  <Stack
                    key={x.id}
                    direction='row'>
                    <Box display='flex' gridGap='8px'>
                      <img src={findEquipSrc(x.equipment?.type)} />
                      <Typography variant='body1'>
                        {humanize(x.equipment?.type)}
                      </Typography>
                    </Box>
                    <Dot active={Boolean(x.currently_triggered)} />
                  </Stack>
                ))}
            </Stack>
          </InnerCard>
        </Stack>
        {ruleGroup.sharedInputs.map((input, index) => (
          <Stack
            key={input}
            spacing={1}
          >
            <RuleTitle>
              {(index > 0) ? 'And' : 'When'}
            </RuleTitle>
            <AutomationInputControl
              ruleId={rootRuleId}
              inputId={input} />
          </Stack>
        ))}
        <InnerCard>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Stack direction='row'>
                <RuleTitle>
                  And activate
                </RuleTitle>
              </Stack>
              <InnerCard>
                <Stack divider>
                  {Object.values(nestedOutputs.filter(x => x.equipment) || {}).map((x, i) => (
                    <Stack
                      key={x.id}
                      direction='row'>
                      <Box display='flex' gridGap='8px'>
                        <img src={findEquipSrc(x.equipment?.type)} />
                        <Typography variant='body1'>
                          {humanize(x.equipment?.type)}
                        </Typography>
                      </Box>
                      <Dot active={Boolean(x.currently_triggered)} />
                    </Stack>
                  ))}
                </Stack>
              </InnerCard>
            </Stack>
            {nestedRule.inputs.filter(x => !ruleGroup.sharedInputs.includes(x.id)).map((input, index) => (
              <Stack
                key={input.id}
                spacing={1}
              >
                <RuleTitle>
                  {(index > 0) ? 'And' : 'When'}
                </RuleTitle>
                <AutomationInputControl
                  ruleId={nestedRule.id}
                  inputId={input.id} />
              </Stack>
            ))}
          </Stack>
        </InnerCard>
        <Footer
          touched={touched}
          loading={loading}
          success={success}
          error={error}
          handleCancel={handleCancel}
          handleSave={handleSave}
        />
      </Stack>
    </AutomationRuleCard>
  )
}

export default RuleGroup
