import {
    Box,
    Button,
    Grid,
    Typography
} from "@material-ui/core"
import { Add } from '@material-ui/icons'
import React, { Fragment, useState } from 'react'
import httpService from 'state-mngt/services/data/http-service'
import {
    Automation,
    AutomationSchedule,
    AutomationTrigger,
    Equipment
} from 'types'
import Stack from 'ui/stack'
import { HISTORY_QUERY_KEYS } from 'utils/constants/customer'
import useDecodedSearchParams from 'utils/hooks/useDecodedSearchParams'
import AutomationRuleCard from './automation-rule-card'
import { DeleteScheduleDialog } from './automations-editor/delete-schedule-dialog'
import Schedule from './schedule'
import {
    selectEquipmentAutomations,
    selectEquipments,
    selectScheduleRules,
    useAutomationsStore,
    useEquipmentStore,
    useRulesStore
} from "./store"
import useClasses from './useClasses'
import { tempId, useSkipFirstRender } from './util'
import { dedupe, useLoadingStore } from "./useGetData"
import Loading from "./loading"
import useCurrentZone from "utils/hooks/useCurrentZone"
import AddNew from "./install/add-new"
import EmptyState from "./empty-state"

// const NewSchedule = ({
//     automation,
//     createNewSchedule,
// }) => {
//     if (!automation) return null

//     return (
//         <Grid
//             xs={12}
//             md={6}
//             lg={4}
//             width={}
//             item>
//             <AutomationRuleCard style={{
//                 display: 'flex', justifyContent: 'center', paddingTop: 16,
//             }}>
//                 <Button
//                     onClick={createNewSchedule}
//                     startIcon={<Add />}
//                 >New schedule</Button>
//             </AutomationRuleCard>
//         </Grid>
//     )
// }

const Schedules = ({ dwellingId }: { dwellingId: number }) => {
    const classes = useClasses()
    const selectedZone = useCurrentZone()

    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const rules = useRulesStore(selectScheduleRules(dwellingId, selectedZone))

    const automations = useAutomationsStore(selectEquipmentAutomations(dwellingId, selectedZone))
    const outputs = rules.flatMap(x => x.outputs)

    // assuming there is one cac automation per zone
    const automation = automations?.[0]

    const equipmentIds = dedupe(outputs?.map(output => output.equipment_id)) || []
    const equipment = useEquipmentStore(selectEquipments(equipmentIds))
    const processedOutputs = outputs.map(x => typeof x.equipment_component === 'number' ?
        ({
            ...x,
            equipment_id: Number(`${x.equipment_id}${x.equipment_component}`),
        }) : x)

    const processedEquipment: Equipment[] = Object
        .values(equipment)
        .filter(x => x.type !== 'humidifier')
        .reduce((prev, curr) => {
            if (curr.type === 'ventilating_dehumidifier') {
                return [
                    ...prev,
                    {
                        ...curr,
                        name: 'Fan',
                        id: Number(`${curr.id}0`),
                    },
                    {
                        ...curr,
                        name: 'Compressor',
                        id: Number(`${curr.id}1`),
                    }]
            }
            return [...prev, curr]
        }, [] as Equipment[])

    const loading = useLoadingStore()

    // list of equipments that've already been selected
    const [selected, setSelected] = useState<{ [key: number]: number[] }>({})

    const setSelectedEquipment = (ruleId: number) => (equipment: Equipment[]) => {
        setSelected(prev => ({ ...prev, [ruleId]: equipment.map(x => x.id) }))
    }

    const getAvailableEquipment = (ruleId: number) => {
        const selectedInOtherRules = Object.keys(selected).filter(key => key !== `${ruleId}`).flatMap(x => selected[x])

        const awailable = processedEquipment
            .filter(equip => !selectedInOtherRules.includes(equip.id))

        return awailable
    }

    const hasAvailableEquipment = Boolean(processedEquipment
        .filter(x => !Object.values(selected).flatMap(x => x).includes(x.id)).length)

    const updateAutomation = (update: Partial<Automation>) => {
        useAutomationsStore.setState(prev => ({
            ...prev,
            ...(automation ? {
                [automation.id]: {
                    ...(prev[automation.id] || {}),
                    ...update,
                },
            } : {}),
        }))
    }

    const disableRule = async (ruleId: number, save: boolean) => {
        const rule = rules.find(x => x.id === ruleId)
        if (!rule) return console.warn('[disableRule] ', 'no rule found')
        if (!automation) return console.warn('[disableRule] ', 'no automation found')

        const updatedAutomation = {
            ...(automation || {}),
            rules: automation.rules
                .map(x => x.id === ruleId ? { ...x, enabled: false } : x),
        }

        useAutomationsStore.setState(prev => ({
            ...prev,
            [automation.id]: updatedAutomation,
        }))

        if (save) await httpService.post(`/automation/${automation.id}`, updatedAutomation)
    }

    const deleteRuleAndSaveAutomation = async (ruleId) => {
        await disableRule(ruleId, ruleId > 0) // negative ID means it's client-side and not yet saved so no need to persist
        const equipToRemove = processedOutputs.map(output => output.equipment_id) || []
        setSelected(prev => ({
            ...prev,
            [ruleId]: prev[ruleId].filter(x => !equipToRemove.includes(x)), // remove the equipment
        }))
    }

    const handleDelete = async (ruleId) => {
        if (rules.length <= 1) return setIsDialogOpen(true)
        await deleteRuleAndSaveAutomation(ruleId)
    }

    const onClickDialogYes = (ruleId) => () => {
        setIsDialogOpen(false)
        deleteRuleAndSaveAutomation(ruleId)
    }

    const onClickDialogCancel = () => {
        setIsDialogOpen(false)
    }

    // creates a new fake rule
    const createNewSchedule = () => {
        const inputId = tempId()
        const outputId = tempId()
        const triggerId = tempId()

        const trigger: AutomationTrigger = {
            id: triggerId,
            enabled: true,
            filtration: false,
            ventilation: false,
            humidity: false,
            inputs: [inputId],
            outputs: [outputId],
            override_on: false,
            override_timeout: null,
        }

        const input: Partial<AutomationSchedule> = {
            id: inputId,
            type: 'schedule',
            currently_triggered: false,
            weekday_awake_interval: 'on_10_off_20',
            weekday_sleep_interval: 'on_10_off_20',
            weekend_awake_interval: 'on_10_off_20',
            weekend_sleep_interval: 'on_10_off_20',
            weekend_start_time: '08:00:00',
            weekend_stop_time: '22:00:00',
            weekday_start_time: '08:00:00',
            weekday_stop_time: '22:00:00',
        }

        const update = {
            inputs: [...(automation?.inputs || []), input],
            rules: [...(automation?.rules || []), trigger],
            dwellingId,
        }

        // @ts-ignore - schedule has a different input type
        updateAutomation(update)
    }

    if (loading) return <Loading />

    return (
        <div className={classes.root}>
            <Typography style={{ marginBottom: '24px' }} variant='h6'>
                Schedules
            </Typography>
            <div className={classes.gridContainer}>
                {rules.map((rule) => (
                    <Fragment key={rule.id}>
                        <Schedule
                            rule={rule}
                            processedOutputs={processedOutputs}
                            equipment={processedEquipment}
                            availableEquipment={getAvailableEquipment(rule.id)}
                            setSelectedEquipment={setSelectedEquipment(rule.id)}
                            handleDelete={handleDelete}
                        />
                        <DeleteScheduleDialog
                            onClickYes={onClickDialogYes(rule.id)}
                            onClickCancel={onClickDialogCancel}
                            isOpen={Boolean(isDialogOpen)}
                        />
                    </Fragment>
                ))}
                {(automation && hasAvailableEquipment) ? (
                    <AddNew
                        onClick={createNewSchedule}
                    >
                        Add schedule
                    </AddNew>
                ) : (
                    <EmptyState>No available equipment</EmptyState>
                )}
            </div>
        </div>
    )
}

export default Schedules
