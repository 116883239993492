import React from 'react'
import {
  selectRule,
  useRulesStore,
} from './store'
import Override from './override'
import AutomationInputControl from './input'
import RuleTitle from './ruleTitle'
import { Box, Typography } from '@material-ui/core'
import Stack from 'ui/stack'
import Dot from './dot'
import AutomationRuleCard from './automation-rule-card'
import Footer from './footer'
import useRuleState from './useRuleState'
import { findEquipSrc } from './static-input'
import { humanize } from 'utils/string-utils'

const Rule = ({ ruleId, setEditing }: { ruleId: number, setEditing: any }) => {
  // const equipment = useEquipmentStore()
  const rule = useRulesStore(selectRule(ruleId))
  // const _equipment = rule.outputs.map(x => equipment[x.equipment_id]).filter(x => x)

  const {
    touched,
    loading,
    success,
    error,
    handleCancel,
    handleSave,
  } = useRuleState(ruleId)

  const _handleCancel = () => {
    setEditing(false)
    handleCancel()
  }

  return (
    <AutomationRuleCard>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Stack direction='row'>
            <RuleTitle>
              Activate
            </RuleTitle>
            <Override
              ruleId={rule.id}
            />
          </Stack>
          <Stack divider>
            {rule.outputs.filter(x => x.equipment).map((x, i) => (
              <Stack
                key={x.id}
                direction='row'>
                <Box display='flex' gridGap='8px'>
                  <img src={findEquipSrc(x.equipment?.type)} />
                  <Typography variant='body1'>
                    {humanize(x.equipment?.type)}
                  </Typography>
                </Box>
                <Dot active={Boolean(x.currently_triggered)} />
              </Stack>
            ))}
          </Stack>
        </Stack>
        {rule.inputs.map((input, index) => (
          <Stack
            key={input.id}
            spacing={1}
          >
            <RuleTitle>
              {(index > 0) ? 'And' : 'When'}
            </RuleTitle>
            <AutomationInputControl
              ruleId={rule.id}
              inputId={input.id} />
          </Stack>
        ))}
        <Footer
          touched={touched}
          loading={loading}
          success={success}
          error={error}
          handleCancel={_handleCancel}
          handleSave={handleSave}
        />
      </Stack>
    </AutomationRuleCard>
  )
}

export default Rule
