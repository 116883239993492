import {
  Slider,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Stack from 'ui/stack'
import Emphasis from './emphasis'
import { InputControlProps } from './input'
import Muted from './muted'
import {
  selectTempInput,
  setTemporaryRuleInput,
  useInitTemporaryRule,
  useRulesStore,
} from './store'
import {
  displayUnit,
  humanizeMeasurement,
  max,
  min,
} from './util'
import useConvertTemperatureUnits from 'utils/hooks/useConvertTemperatureUnits'

// const recommendedValue = unit => {
//   switch (unit) {
//     case '°C': return 13
//     case '°F': return 55
//     case '%RH': return 60
//     default: return ''
//   }
// }

// const recommended = (unit) => ({
//   value: recommendedValue(unit),
//   label: `${recommendedValue(unit)}${unit}`,
// })

const marks = ({ min, max, unit }) => [
  {
    value: min,
    label: `${min}${unit}`,
  },
  // {
  //   ...recommended(unit),
  // },
  {
    value: max,
    label: `${max}${unit}`,
  },
]

function Humidity({
  ruleId,
  inputId,
}: InputControlProps) {
  const [localValue, setLocalValue] = useState<number | undefined>() // store it locally just so it updates visually on change without rendering the whole tree
  const [localRange, setLocalRange] = useState<number[] | undefined>() // store it locally just so it updates visually on change without rendering the whole tree

  useInitTemporaryRule(ruleId)
  // inputs all have different shapes so typing them is borken
  const input = useRulesStore(selectTempInput<any>(ruleId, inputId))

  const { format, display, convertedUnit } = useConvertTemperatureUnits(input)

  useEffect(() => {
    if (input?.start_set_point) setLocalValue(display(input.start_set_point))
  }, [input?.start_set_point, display])

  useEffect(() => {
    if (input?.high_end_start_set_point && input?.low_end_start_set_point) {
      setLocalRange([
        display(input.low_end_start_set_point),
        display(input.high_end_start_set_point),
      ])
    }
  }, [
    input?.high_end_start_set_point,
    input?.low_end_start_set_point,
    display,
  ])

  const handleChangeCommitted = (event, value) => {
    if (Array.isArray(value)) {
      setTemporaryRuleInput(ruleId, input?.id, {
        high_end_start_set_point: format(value[1]),
        low_end_start_set_point: format(value[0]),
      })
    } else {
      setTemporaryRuleInput(ruleId, input?.id, {
        start_set_point: format(value),
      })
    }
  }

  if (!input) return null

  const onChange = (event, value) => {
    if (Array.isArray(value)) {
      setLocalRange(value)
    } else {
      setLocalValue(value)
    }
  }

  const {
    measurement,
    rising,
    type,
  } = input

  const _min = min(measurement, convertedUnit, rising)
  const _max = max(measurement, convertedUnit, rising)

  const _marks = marks({
    min: min(measurement, convertedUnit, rising),
    max: max(measurement, convertedUnit, rising),
    unit: displayUnit(convertedUnit || input.measurement),
  })

  const triggerAction = input.rising ? 'rises above' : 'drops below'
  const humanizedMeasurement = humanizeMeasurement(type, measurement)

  const renderDidactic = () => {
    if (localRange && localRange[0] && localRange[1]) {
      return (
        <>
          <Emphasis>
            {humanizedMeasurement}
          </Emphasis>
          {` `}falls out of range{` `}
          <Emphasis>
            {localRange[0]} and{` `}
          </Emphasis>
          <Emphasis>
            {localRange[1]}{displayUnit(convertedUnit || measurement)}
          </Emphasis>
        </>
      )
    }
    if (localValue) {
      return (
        <>
          <Emphasis>
            {humanizedMeasurement}
          </Emphasis>
          {` `} {triggerAction} {` `}
          <Emphasis>
            {localValue}{displayUnit(convertedUnit || measurement)}
          </Emphasis>
        </>
      )
    }
    return null
  }

  return (
    <Stack>
      <Typography
        variant='body1'
      >
        <Muted>
          {renderDidactic()}
        </Muted>
      </Typography>
      {(localValue || localRange) && (
        <Slider
          track={rising ? 'inverted' : 'normal'}
          value={localValue || localRange}
          min={_min}
          max={_max}
          marks={_marks}
          onChange={onChange}
          onChangeCommitted={handleChangeCommitted}
          aria-labelledby={input.type} />
      )}
    </Stack>
  )
}

export {
  min,
  max,
}

export default Humidity
