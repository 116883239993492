import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Typography,
} from '@material-ui/core'
import React, {
  useEffect,
  useState,
} from 'react'
import { theme } from 'theme'
import { InputControlProps } from './input'
import {
  selectTempInput,
  setTemporaryRuleInput,
  useInitTemporaryRule,
  useRulesStore,
} from './store'
import {
  displayUnit,
  humanizeMeasurement,
  max,
  min,
  start_set_point,
} from './util'
import Stack from 'ui/stack'
import Muted from './muted'
import Emphasis from './emphasis'

function OutdoorAir({
  ruleId,
  inputId,
}: InputControlProps) {
  const [selectedFactor, setSelectedFactor] = useState('1')
  useInitTemporaryRule(ruleId)
  const input = useRulesStore(selectTempInput(ruleId, inputId))

  useEffect(() => {
    if (!input?.measurement || !start_set_point) return
    setSelectedFactor(`${input?.start_set_point}`)
  }, [input?.measurement, input?.start_set_point])

  if (!input) return null

  const handleChange = (event, value) => {
    setSelectedFactor(value)
    if (value === 'custom') return
    return setTemporaryRuleInput(ruleId, input.id, {
      start_set_point: parseInt(value),
      stop_set_point: parseInt(value),
    })
  }

  // const triggerAction = input.rising ? 'rises above' : 'drops below'
  // const humanizedMeasurement = humanizeMeasurement(input.type, input.measurement)
  // const humanizedFactor =
  //     <>
  //         <Emphasis>
  //             {start_set_point[input.measurement]['aqi'][selectedFactor]}
  //         </Emphasis> <Muted>
  //         </Muted>
  //     </>

  return (
    <Box
      padding='16px'
      borderRadius='10px'
      border={`1px solid ${theme.palette.charcoalLightTint}`}
      borderColor='charcoalLightTint'
    >
      <Stack>
        <Typography
          variant='body1'
        >
          <Muted>
            <Emphasis>
              Outdoor AQI {` `}
            </Emphasis>
            is within
          </Muted>
        </Typography>
        <RadioGroup
          value={selectedFactor}
          aria-label={input.measurement}
          name={input.measurement}
          onChange={handleChange}>
          <FormControlLabel value='1' control={<Radio />} label={
            <Typography>
              1 - 50
              <Muted> (good range)</Muted>
            </Typography>
          } />
          <FormControlLabel value='2' control={<Radio />} label={
            <Typography>
              50 - 100
              <Muted> (fair range)</Muted>
            </Typography>
          } />
        </RadioGroup>
      </Stack>
    </Box>
  )
}

export default OutdoorAir
