import { Automation, AutomationRule } from "types"
import {
  selectAutomation,
  selectRule,
  tempKey,
  useAutomationsStore,
  useRulesStore,
} from "./store"
import httpService from "state-mngt/services/data/http-service"
import { tempId } from "./util"

// const merge = (entity, entities) => {
//   return entities.map(x => x.id === entity.id ? entity : x)
// }

// const replaceIds = (oldInputs, newInputs, inputIdsToRemove) => [
//   ...oldInputs.filter(x => inputIdsToRemove.some(y => x !== x)),
//   ...newInputs,
// ].map(x => x.id)

/**
 * saving an automation requires creating new inputs
 * with the changes and saving those to the automation
 */
const mergeRule = (tempRule: AutomationRule, automation: Automation): Automation => {
  // create new inputs for each input in the rule
  const ruleId = tempRule.id
  const newInputs = tempRule.inputs.map(x => ({ ...x, id: tempId() }))

  // create a new automation rule with the new inputs
  const newRule = {
    ...tempRule.trigger,
    id: tempId(),
    inputs: newInputs.map(x => x.id),
  }

  const newRules = [
    // disabled old rule
    ...automation.rules.map(x => x.id === ruleId ? { ...x, enabled: false } : x),
    newRule,
  ]

  // replace automation outputs with temp outputs
  const newOutputs = automation.outputs.map(x => {
    const match = tempRule.outputs.find(y => y.id === x.id)
    if (match) return match
    return x
  })

  return {
    ...automation,
    rules: newRules,
    outputs: newOutputs,
    inputs: [...automation.inputs, ...newInputs],
  }
}

function useMergeTemporaryRuleIntoAutomation(ruleId) {
  const tempRule = useRulesStore(selectRule(tempKey(ruleId)))
  const automation = useAutomationsStore(selectAutomation(tempRule?.automationId))
  if (!tempRule || !automation) return null
  return mergeRule(tempRule, automation)
}

async function save(automation) {
  try {
    await httpService.post(`/automation/${automation.id}`, automation)
  } catch (e) {
    console.error(e)
  }
}

/**
 * used for persisting an automation that has been
 * changed via a temporary rule. merges the temporary
 * rule into the automation, and then saves the changes
 */
function useSaveTemporaryRuleToAutomation(ruleId?: number) {
  if (!ruleId) return () => Promise.resolve()
  const mergedAutomation = useMergeTemporaryRuleIntoAutomation(ruleId)
  if (!mergedAutomation) return () => Promise.resolve()
  return () => save(mergedAutomation)
}

export {
  useMergeTemporaryRuleIntoAutomation,
}

export default useSaveTemporaryRuleToAutomation
