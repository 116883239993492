import { UserPreferencesEnum } from 'utils/constants/preference-enums'

export const toCapitalize = (string: string) => string.replace(/(^[a-z]|\s[a-z])/g, (letter: string) => letter.toUpperCase())

const falsyStr = fn => x => x ? fn(x) : ''
export const capitalize = falsyStr(string => `${string[0].toUpperCase()}${string.slice(1)}`)
export const removeTitleCase = falsyStr(string => capitalize(string.toLowerCase()))
export const humanize = str => {
  return capitalize(str.replace(/_/g, ' ').replace(/-/g, ' '))
}

/**
 * This will convert a unit string into its abbreviated version. For example: celsius to °C.
 * @param unit The unit we which will be abbreviated.
 * @return string for the unit or an empty string if the unit is invalid.
 */
export const toAbbreviatedUnit = (unit: UserPreferencesEnum | string): string => {
  if (unit === UserPreferencesEnum.PM_COUNT)
    return 'count/m³'
  if (unit === UserPreferencesEnum.PM_MC)
    return 'μg/m³'
  if (unit === UserPreferencesEnum.VOC_COUNT)
    return 'ppb'
  if (unit === UserPreferencesEnum.VOC_MC)
    return 'μg/m³'
  if (unit === UserPreferencesEnum.FAHRENHEIT)
    return '°F'
  if (unit === UserPreferencesEnum.CELSIUS)
    return '°C'
  if (unit === UserPreferencesEnum.FEET)
    return 'fpm'
  if (unit === UserPreferencesEnum.METRES)
    return 'm/s'

  return ''
}
