import {
  Box,
  Breadcrumbs,
  Tab,
  TabProps,
  Tabs,
  Typography,
  createStyles,
  withStyles
} from '@material-ui/core'
import CustomerSelectZone from 'features/customer-drill-down/customer-select-zone'
import React, { useEffect, useState } from 'react'
import {
  Link,
  Outlet,
  useLocation,
  useParams,
} from 'react-router-dom'
import { ROUTE_PATHS } from 'routes/routes'
import Stack from 'ui/stack'
import { HISTORY_QUERY_KEYS } from 'utils/constants/customer'
import { devicesTooltip } from 'utils/constants/tooltip-content'
import { useStateInURLQueryParameter } from 'utils/hooks/router'
import useAdmin from 'utils/hooks/useAdmin'
import useAllZones from 'utils/hooks/useAllZones'
import useCurrentDwellingDetails from 'utils/hooks/useCurrentDwellingDetails'
import useHasMonitorInZone from 'utils/hooks/useHasMonitorInZone'
import Muted from './equipment/muted'
import useCurrentZone from 'utils/hooks/useCurrentZone'
import { BaseProps } from '@material-ui/core/OverridableComponent'
import ReportButton from './report-button'
import { theme } from 'theme'

const StyledTab = withStyles(() => createStyles({
  root: {
    minWidth: 140,
  },
}))((props: TabProps & BaseProps<any>) => <Tab disableRipple {...props} />)

function SecondaryNav() {
  const location = useLocation()
  const { dwellingId } = useParams()
  const zone = useCurrentZone()

  if (!dwellingId) return <Muted>No dwelling Id</Muted>

  const pathToIndex = {
    [`/customers/${dwellingId}`]: 0,
    [`/customers/${dwellingId}/automations`]: 1,
    [`/customers/${dwellingId}/schedules`]: 2,
    [`/customers/${dwellingId}/interlocks`]: 3,
    [`/customers/${dwellingId}/alerts`]: 4,
    [`/customers/${dwellingId}/equipment`]: 5,
    [`/customers/${dwellingId}/profile`]: 6,
  };

  let newSearch = ''

  if (zone) {
    newSearch = `?${HISTORY_QUERY_KEYS.zone}=${zone.replace('#', '%23')}`
  }

  const [selectedTab, setSelectedTab] = useState(pathToIndex[location.pathname])

  React.useEffect(() => {
    setSelectedTab(pathToIndex[location.pathname])
  }, [location.pathname])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <Tabs value={selectedTab} onChange={handleTabChange}>
      <StyledTab
        component={Link}
        value={0}
        label='Charts'
        to={`/customers/${dwellingId.toString()}${newSearch}`}
      />
      <StyledTab
        value={1}
        label='Automations'
        component={Link}
        to={`/customers/${dwellingId.toString()}/automations${newSearch}`}
      />
      <StyledTab
        value={2}
        label='Schedules'
        component={Link}
        to={`/customers/${dwellingId.toString()}/schedules${newSearch}`}
      />
      <StyledTab
        value={3}
        label='Interlocks'
        component={Link}
        to={`/customers/${dwellingId.toString()}/interlocks${newSearch}`}
      />
      <StyledTab
        value={4}
        label='Alerts'
        component={Link}
        to={`/customers/${dwellingId.toString()}/alerts${newSearch}`}
      />
      <StyledTab
        value={5}
        label='Equipment'
        component={Link}
        to={`/customers/${dwellingId.toString()}/equipment${newSearch}`}
      />
      <StyledTab
        value={6}
        label='Customer'
        component={Link}
        to={`/customers/${dwellingId.toString()}/profile${newSearch}`}
      />
    </Tabs>
  )
}

export const CustomerLayout = ({ zone, setZone }) => {
  const showReportButton = true

  const dwellingDetails = useCurrentDwellingDetails()
  const admin = useAdmin()
  const hasMonitor = useHasMonitorInZone()
  const allZones = useAllZones()

  return (
    <>
      <Box
        p={2}
        pl={3}
        display="flex"
        alignItems="center"
        justifyContent='space-between'
      >
        <Box
          display="flex"
          alignItems="center"
        >
          {hasMonitor ? (
            <>
              <Breadcrumbs>
                <Link to={ROUTE_PATHS.customers.root.absolute}>
                  <Typography color="textPrimary">
                    Customers
                  </Typography>
                </Link>
                <Typography color="textPrimary">
                  {(dwellingDetails && admin) ? (
                    <>{admin?.last_name} - {dwellingDetails?.name} ({dwellingDetails?.street_1})</>
                  ) : (
                    <Muted>Loading dwelling...</Muted>
                  )}
                </Typography>
                <CustomerSelectZone
                  allZones={allZones}
                  setZone={setZone}
                />
              </Breadcrumbs>
              {devicesTooltip}
            </>
          ) : (
            <Stack justify='flex-start' direction='row'>
              <Typography><Muted>No monitor found</Muted></Typography>
              <CustomerSelectZone
                allZones={allZones}
                setZone={setZone}
              />
            </Stack>
          )}
        </Box>
        {showReportButton && hasMonitor && (
          <ReportButton zone={zone} />
        )}
      </Box>
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        <SecondaryNav />
      </Box>
      <Box
        bgcolor={theme.palette.charcoalLightestTint}
        p={4}
        m={0}
      >
        <Outlet />
      </Box>
    </>
  )
}
