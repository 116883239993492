import { TimeInterval } from "utils/constants/time-interval"
import { GANTT_CHART } from "./constants"
import {
  genericPluck,
  selectDevices,
  selectEquipments,
  selectRulesByAutomationIds,
  useAutomationsStore,
  useDevicesStore,
  useDwellingAutomationsStore,
  useEquipmentStore,
  useLogsStore,
  useRulesStore,
} from "features/customer-drill-down/equipment/store"
import { Automation, AutomationLog } from "types"
import { useEffect, useState } from "react"
import useAutomationIds from "features/customer-drill-down/equipment/useAutomationIds"
import { getAirflowData, getCacChannelData, getSeriesColor } from "./gantt-chart-utils"
import { SeriesXrangeOptions, XrangePointOptionsObject, YAxisOptions } from "highcharts"
import httpService from "state-mngt/services/data/http-service"
import { humanize, removeTitleCase } from "utils/string-utils"
import { SERIES_COLORS } from "./models"
import { dedupe } from "features/customer-drill-down/equipment/useGetData"
import { getApiTimeInterval } from "utils/http-utils"
import useCurrentZone from "utils/hooks/useCurrentZone"

const options = ({
  categoriesLength,
  series,
  interactive,
  yAxis,
  events,
}): Highcharts.Options => {

  return {
    title: {
      text: '', // no title on mockup
    },
    time: {
      useUTC: false,
    },
    chart: {
      backgroundColor: 'transparent',
      ignoreHiddenSeries: false, // added to display xAxis when there are no telemetry data in selected time frame,
      height: interactive ?
        categoriesLength * GANTT_CHART.seriesHeight + GANTT_CHART.axisHeight :
        categoriesLength * 16 + GANTT_CHART.axisHeight,
      marginLeft: interactive ?
        302 :
        64,
      marginRight: interactive ?
        56 :
        32,
      panning: {
        enabled: interactive ?
          true :
          false,
        type: 'x',
      },
      spacingTop: 0,
      reflow: true,
      style: {
        fontFamily: 'inherit',
        fontSize: '14px',
        cursor: interactive ? 'grab' : 'default',
      },
    },
    plotOptions: {
      xrange: {
        grouping: false,
        pointPadding: 0,
        minPointLength: 1, // required for 0 values (interval's x equals x2) to show,
      },
    },
    credits: {
      enabled: false, // hide default message 'created by Highcharts'
    },
    noData: {
      attr: {
        zIndex: 3,
      },
    },
    tooltip: interactive ? {
      pointFormat: '<span style="color:{point.color}">●</span> <b>Active</b><br/>', // custom content only display 'active'
      outside: true,
    } : {
      enabled: false,
    },
    series,
    xAxis: {
      type: 'datetime',
      minTickInterval: TimeInterval.MINUTE * 5, // lowest data point,
      minRange: TimeInterval.MINUTE * 5,
      events,
      maxPadding: 0,
      gridLineWidth: 1,
      gridLineDashStyle: 'LongDash',
      lineWidth: 0,
    },
    yAxis,
  }
}

interface GanttLegend {
  title: string
  color: string
}

const useHasHardwiredFan = (dwellingId) => {
  const [hasHardwiredFan, setHas] = useState(false)
  const ids = useDwellingAutomationsStore(store => store?.[dwellingId])
  const a = useAutomationsStore(genericPluck<Automation>(ids || []))
  const zone = useCurrentZone()

  useEffect(() => {
    if (!Object.keys(a || {}).length) return

    const automationsInZone = Object.values(a).filter(x => x.zone.toLowerCase() === zone.toLowerCase())
    setHas(automationsInZone.some(x => x.template?.toLowerCase() === 'demand_control_ventilation_1'))
  }, [
    zone,
    Object.keys(a || {}).length,
  ])

  return hasHardwiredFan
}

const useGanttChartOptions = ({
  dataMin,
  dataMax,
  dwellingId,
  interactive,
  secondDataType,
  events,
  monitorTelemetry,
}: any) => {
  const [ganttLegend, setGanttLegend] = useState<GanttLegend[]>([])
  const [ganttChartOptions, setGanttChartOptions] = useState<Highcharts.Options>({})
  const [ids, setIds] = useState<{
    equipmentIds: number[],
    deviceIds: number[],
  }>({
    equipmentIds: [],
    deviceIds: [],
  })
  const [cacTelemetry, setCacTelemetry] = useState<XrangePointOptionsObject[]>([])
  const automationIds = useAutomationIds(dwellingId)

  const rules = useRulesStore(selectRulesByAutomationIds(automationIds || []))
  const equipment = useEquipmentStore(selectEquipments(ids.equipmentIds))
  const devices = useDevicesStore(selectDevices(ids.deviceIds))

  const airflowEquip = Object.values(equipment).find(equip => equip.airflowEquipment)
  const hasHardwiredFan = useHasHardwiredFan(dwellingId)
  const zone = useCurrentZone()

  const logs = useLogsStore()

  const [outputMap, setOutputMap] = useState<{
    [outputId: number]: {
      equipmentId: number,
      deviceId: number,
      terminal: number,
      telemetry?: any[]
    }
  }>({})

  useEffect(() => {
    const cacDevices = Object.values(devices).filter(x => x.type === 'cac')

    if (!cacDevices.length) return
    if (!dataMin || !dataMax) return

    const getTelemetryBefore = new Date('2023-10-19 00:00:00') // more recent uses automation logs
    if (dataMin > getTelemetryBefore) return

    const interval = getApiTimeInterval(dataMin, dataMax)

    const getCacTelemetry = async () => {
      try {
        // device is linked to 
        const proms = cacDevices.map(device => httpService.get<any[]>(
          `/device/${device.id}/telemetry_range?start_time=${dataMin.toISOString()}&end_time=${getTelemetryBefore.toISOString()}&interval=${interval}`,
        ))

        const telemetryResponses = await Promise.all(proms)

        const _cacTelemetry = telemetryResponses
          .map((telem, index) => {
            const deviceId = cacDevices[index].id

            const findYValue = terminal => {
              const value = Object.values(outputMap).findIndex(x =>
                (x.deviceId === deviceId) && (x.terminal === terminal))

              return value
            }

            return [
              ...((findYValue(0) >= 0) ?
                getCacChannelData(telem, 0).map(x => ({
                  ...x,
                  y: findYValue(0),
                  color: getSeriesColor(findYValue(0)),
                })) :
                []),
              ...((findYValue(1) >= 0) ? getCacChannelData(telem, 0).map(x => ({
                ...x,
                y: findYValue(1),
                color: getSeriesColor(findYValue(1)),
              })) : []),
            ]
          })
          .flatMap(x => x)

        setCacTelemetry(_cacTelemetry)
      } catch (e) {
        console.error(e)
      }
    }

    getCacTelemetry()
  }, [
    JSON.stringify(outputMap),
    Object.values(devices || {}).length,
    dataMin?.getTime(),
    dataMax?.getTime(),
  ])

  useEffect(() => {
    if (!Object.keys(rules || {})?.length) return

    // for when logs response changes
    const findLogsByOutput = outputId =>
      Object.values(logs || {})
        .filter(log =>
          (log?.rules || []).some(rule =>
            (rule?.outputs || []).some(output => outputId === output)))

    const rulesInZone = Object.values(rules).filter(rule => rule.zone.toLowerCase() === zone.toLowerCase())
    const outputIds = dedupe(rulesInZone.flatMap(rule => rule.outputs.filter(x => x.type !== 'slack').map(x => x.id)))
    const equipmentIds = rulesInZone.flatMap(rule => rule.outputs.map(x => x.equipment_id))
    const deviceIds = rulesInZone.flatMap(rule => rule.outputs.map(x => x.device_id))

    setOutputMap(outputIds.reduce((prev, curr) => {
      const output = rulesInZone
        .find(x => x.outputs.some(output => output.id === curr))
        ?.['outputs'].find(x => x.id === curr)

      if (!output) return prev
      if (!output.device_id && !output.equipment_id) return prev

      return {
        ...prev,
        [curr]: {
          equipmentId: output?.equipment_id,
          logs: findLogsByOutput(curr),
          deviceId: output?.device_id,
          terminal: output?.terminal,
        },
      }
    }, {
    }))

    setIds(prev => ({
      ...prev,
      equipmentIds,
      deviceIds,
    }))
  }, [
    zone,
    Object.keys(rules || {}).join(''),
    Object.keys(logs || {}).join(''),
  ])

  useEffect(() => {
    const logsToChartPoints = (logs: AutomationLog[], index: number): XrangePointOptionsObject[] => {
      return logs.filter(x => x.start_timestamp && x.stop_timestamp).map(log => ({
        x: new Date(log.start_timestamp).getTime(),
        x2: (new Date(log.stop_timestamp).getTime() || Date.now()), // if no stop time, chart until now
        y: index,
        color: getSeriesColor(index),
      }))
    }

    const name = (outputId: number, terminal?: number) => {
      const { deviceId, equipmentId } = outputMap[outputId]
      const _device = devices[deviceId]
      const _equipment = equipment[equipmentId]

      let name = removeTitleCase(_equipment?.brand || '')
      name = `${_equipment?.brand} ${_equipment?.model}`
      if (!name) name = humanize(_equipment.type)

      if (name.toLowerCase().includes('ventilating dehumidifier')) {
        const suffix = terminal ? 'fan' : 'compressor'
        name = `Dehumidifier (${suffix}) `
      }

      if (!_device || !_equipment) return ''
      if (_device.plastic_serial_number) return `<b>${name}</b>&nbsp;[Controller: <b>${_device.plastic_serial_number}</b>]`
      return `<b>${name}</b>`
    }

    const airflowEquipmentName = removeTitleCase(airflowEquip?.name) || 'Air handler'

    const categories = dedupe([
      ...Object.keys(outputMap).map(outputId =>
        name(parseInt(outputId), outputMap[outputId].terminal)).filter(x => x),
      ...(hasHardwiredFan ? [`<b>${airflowEquipmentName} (fan)</b>&nbsp;[Hardwired]`] : []),
      `<b>Airflow</b>&nbsp;[${airflowEquipmentName} return]`,
    ])

    setGanttLegend(categories.map((x, i) => ({
      title: x, color: getSeriesColor(i),
    })))

    const data = [
      ...cacTelemetry,
      ...(Object.keys(outputMap)
        .map((key, index) =>
          logsToChartPoints(outputMap[key]?.logs || [], index))
        .flatMap(x => x)),
      ...(hasHardwiredFan ? [{
        x: dataMin?.getTime(),
        x2: dataMax?.getTime(),
        y: categories.length - 2,
        color: getSeriesColor(categories.length - 2),
      }] : []),
      ...(monitorTelemetry?.length ?
        getAirflowData(monitorTelemetry).map(x => ({
          ...x,
          y: categories.length - 1,
          color: SERIES_COLORS[categories.length - 1],
        })) :
        [{
          x: 0,
          x2: 0,
          y: categories.length - 1,
        }] // fake a data point to get the category to show up even if no data
      ),
    ]

    const series: SeriesXrangeOptions[] = [{
      name: 'Equipment gantt',
      type: 'xrange' as const,
      data,
      pointWidth: interactive ? 20 : 16,
      showInLegend: false,
      borderColor: 'transparent',
      borderRadius: 0,
    }]

    const yAxis: YAxisOptions = {
      categories: categories,
      title: {
        text: '',
      },
      labels: {
        enabled: interactive ? true : false,
        step: 1,
      },
      showFirstLabel: true,
      showLastLabel: true,
    }

    setGanttChartOptions(options({
      categoriesLength: categories.length,
      series,
      interactive,
      events,
      yAxis,
    }))
  }, [
    Object.keys(equipment || {}).join(''),
    Object.keys(devices || {}).join(''),
    Object.keys(outputMap || {}).join(''),
    cacTelemetry.length,
    JSON.stringify(monitorTelemetry),
    hasHardwiredFan,
    dataMin?.getTime(),
    dataMax?.getTime(),
    secondDataType,
  ])

  return {
    ganttChartOptions, ganttLegend,
  }
}

export default useGanttChartOptions
