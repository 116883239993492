import React, {
  useState,
} from 'react'
import {
  persistTemporaryRule,
  resetTemporaryRule,
  selectTempInput,
  setTemporaryRule,
  setTemporaryRuleInput,
  useInitTemporaryRule,
  useRulesStore,
} from "./store"
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Tab,
  Typography,
} from "@material-ui/core"
import {
  AutomationRuleCardNoPadding,
  Padding,
} from './automation-rule-card'
import RuleTitle from './ruleTitle'
import Footer from './footer'
import { useResetAfterTimeout } from './useRuleState'
import {
  TabContext,
  TabList,
  TabPanel,
} from '@material-ui/lab'
import Stack from 'ui/stack'
import useIsTouched from './useIsTouched'
import ScheduleEquipment from './scheduleEquipment'
import { Equipment } from 'types'
import useSaveTemporaryRuleToAutomation from './useSaveTemporaryRuleToAutomation'
import ButtonSpinner from './button-spinner'
import { theme } from 'theme'
import EntityHeader from './entity-header'

const formatData = ({ key, value }) => {
  if (key.includes('time')) return {
    [key]: `${value}:00`,
  }
  return {
    [key]: value,
  }
}

const Schedule = ({
  rule,
  equipment,
  handleDelete,
  processedOutputs,
  availableEquipment,
  setSelectedEquipment,
}) => {
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [success, setSuccess] = useState('')
  const [error, setError] = useState('')
  const input = rule.inputs.find(x => x.type === 'schedule')

  const outputsByRule = rule.outputs.map(x => processedOutputs.find(rule => x.id === rule.id))

  useInitTemporaryRule(rule.id)
  const tempInput = useRulesStore(selectTempInput(rule.id, input?.id))

  useResetAfterTimeout([success, error], [setSuccess, setError])
  const save = useSaveTemporaryRuleToAutomation(rule.id)
  const initialEquipment = outputsByRule
    .map(x => Object.values(equipment || [])
      // @ts-ignore
      .find(y => y.id === x.equipment_id))
    .filter(x => x)

  const onChangeEquipment = (_equipment: Equipment[]) => {
    const equipmentIds = _equipment.map(x => x.id)
    const newOutputs = outputsByRule?.filter(x => equipmentIds.includes(x.equipment_id))

    // if there are any venti dehum equipment here we must map it to
    // correct cac output

    setSelectedEquipment(_equipment)

    const update = {
      outputs: newOutputs,
      trigger: {
        ...rule.trigger,
        outputs: newOutputs?.map(x => x.id),
      },
    }

    setTemporaryRule(rule.id, update)
  }

  const touched = useIsTouched(rule.id, [
    `weekday_awake_interval`,
    `weekday_sleep_interval`,
    `weekday_start_time`,
    `weekday_stop_time`,
    // `weekend_awake_interval`,
    // `weekend_sleep_interval`,
    // `weekend_start_time`,
    // `weekend_stop_time`,
  ])

  const handleChange = (e) => {
    if (!input) return
    if (!e.target.name || !e.target.value) return console.error('[handleChange] missing values')
    const data = formatData({
      key: e.target.name, value: e.target.value,
    })
    setTemporaryRuleInput(rule.id, input.id, data)
  }

  const handleCancel = () => {
    resetTemporaryRule(rule.id)
  }

  const handleSave = async () => {
    setLoading(true)
    try {
      await save()
      persistTemporaryRule(rule.id)
      setSuccess('Saved ✓')
    } catch (e) {
      console.error(e)
      setError('Couldn\'t save')
    }
    setLoading(false)
  }

  const _handleDelete = async () => {
    setDeleteLoading(true)
    await handleDelete(rule.id)
    setDeleteLoading(false)
  }

  const _loading = loading || deleteLoading

  if (!input) return null
  if (!tempInput) return null

  return (
    <AutomationRuleCardNoPadding>
      <Stack spacing={4}>
        <Box mx={2} mt={1}>
          <Stack spacing={2}>
            <EntityHeader
              loading={_loading}
              handleDelete={_handleDelete}
            >
              Schedule
            </EntityHeader>
            <RuleTitle>
              Activates
            </RuleTitle>
            <ScheduleEquipment
              equipment={Object.values(equipment)}
              initialEquipment={initialEquipment}
              availableEquipment={availableEquipment}
              onChangeEquipment={onChangeEquipment}
            />
          </Stack>
        </Box>
        <Box px={2}>
          <Stack spacing={2}>
            <RuleTitle>
              Awake
            </RuleTitle>
            <FormControl
              size='small'
              variant='outlined'
              style={{
                width: '100%',
              }}
            >
              <InputLabel
                htmlFor={`weekday_awake_interval`}
              >
                Interval
              </InputLabel>
              <Select
                id={`weekday_awake_interval`}
                name={`weekday_awake_interval`}
                labelId={`weekday_awake_interval`}
                label='Interval'
                value={tempInput[`weekday_awake_interval`]}
                disabled={loading}
                onChange={handleChange}
              >
                <MenuItem
                  value='always_on'
                >
                  Always on
                </MenuItem>
                <MenuItem
                  value='always_off'
                >
                  Always off
                </MenuItem>
                <MenuItem
                  value='on_5_off_55'
                >
                  On 5min off 55min
                </MenuItem>
                <MenuItem
                  value='on_5_off_25'
                >
                  On 5min off 25min
                </MenuItem>
                <MenuItem
                  value='on_10_off_20'
                >
                  On 10min off 20min
                </MenuItem>
                <MenuItem
                  value='on_20_off_20'
                >
                  On 20min off 20min
                </MenuItem>
                <MenuItem
                  value='on_20_off_10'
                >
                  On 20min off 10min
                </MenuItem>
              </Select>
            </FormControl>
            <Stack
              direction='row'
              wrap='nowrap'
              itemStyles={[{
                flex: 1,
              }]}
            >
              <FormControl
                size='small'
                variant='outlined'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel
                  htmlFor={`weekday_start_time`}
                >
                  Start
                </InputLabel>
                <OutlinedInput
                  id={`weekday_start_time`}
                  name={`weekday_start_time`}
                  label='Interval'
                  type='time'
                  disabled={loading}
                  value={tempInput[`weekday_start_time`]}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl
                size='small'
                variant='outlined'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel
                  htmlFor={`weekday_stop_time`}
                >
                  End
                </InputLabel>
                <OutlinedInput
                  id={`weekday_stop_time`}
                  name={`weekday_stop_time`}
                  label='Interval'
                  type='time'
                  disabled={loading}
                  value={tempInput[`weekday_stop_time`]}
                  onChange={handleChange}
                  style={{
                    cursor: 'pointer',
                  }}
                />
              </FormControl>
            </Stack>
          </Stack>
          <Box mt={3}>
            <Stack spacing={2}>
              <RuleTitle>
                Asleep
              </RuleTitle>
              <FormControl
                size='small'
                variant='outlined'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel
                  htmlFor={`weekday_sleep_interval`}
                >
                  Interval
                </InputLabel>
                <Select
                  id={`weekday_sleep_interval`}
                  name={`weekday_sleep_interval`}
                  labelId={`weekday_sleep_interval`}
                  label='Interval'
                  disabled={loading}
                  value={tempInput[`weekday_sleep_interval`]}
                  onChange={handleChange}
                >
                  <MenuItem
                    value='always_on'
                  >
                    Always on
                  </MenuItem>
                  <MenuItem
                    value='always_off'
                  >
                    Always off
                  </MenuItem>
                  <MenuItem
                    value='on_5_off_55'
                  >
                    On 5m off 55m
                  </MenuItem>
                  <MenuItem
                    value='on_5_off_25'
                  >
                    On 5m off 25m
                  </MenuItem>
                  <MenuItem
                    value='on_10_off_20'
                  >
                    On 10m off 20m
                  </MenuItem>
                  <MenuItem
                    value='on_20_off_20'
                  >
                    On 20m off 20m
                  </MenuItem>
                  <MenuItem
                    value='on_20_off_10'
                  >
                    On 20m off 10m
                  </MenuItem>
                </Select>
              </FormControl>
              <Stack
                direction='row'
                wrap='nowrap'
                itemStyles={[{
                  flex: 1,
                }]}
              >
                <FormControl
                  size='small'
                  variant='outlined'
                  style={{
                    width: '100%',
                  }}
                >
                  <InputLabel
                    htmlFor={`weekday_stop_time`}
                  >
                    Start
                  </InputLabel>
                  <OutlinedInput
                    id={`weekday_stop_time`}
                    name={`weekday_stop_time`}
                    label='Interval'
                    type='time'
                    disabled
                    value={tempInput[`weekday_stop_time`]}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  size='small'
                  variant='outlined'
                  style={{
                    width: '100%',
                  }}
                >
                  <InputLabel
                    htmlFor={`weekday_start_time`}
                  >
                    End
                  </InputLabel>
                  <OutlinedInput
                    id={`weekday_start_time`}
                    name={`weekday_start_time`}
                    label='Interval'
                    type='time'
                    disabled
                    value={tempInput[`weekday_start_time`]}
                    onChange={handleChange}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Box>
        </Box>
        {/* </Box>
          </TabPanel>
          <TabPanel
            style={{
              padding: 16,
            }}
            value='weekend'
          >
            <Stack spacing={2}>
              <RuleTitle>
                Awake
              </RuleTitle>
              <FormControl
                size='small'
                variant='outlined'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel
                  htmlFor={`weekend_awake_interval`}
                >
                  Interval
                </InputLabel>
                <Select
                  id={`weekend_awake_interval`}
                  name={`weekend_awake_interval`}
                  labelId={`weekend_awake_interval`}
                  label='Interval'
                  value={tempInput[`weekend_awake_interval`]}
                  disabled={loading}
                  onChange={handleChange}
                >
                  <MenuItem
                    value='always_on'
                  >
                    Always on
                  </MenuItem>
                  <MenuItem
                    value='always_off'
                  >
                    Always off
                  </MenuItem>
                  <MenuItem
                    value='on_5_off_55'
                  >
                    On 5m off 55m
                  </MenuItem>
                  <MenuItem
                    value='on_5_off_25'
                  >
                    On 5m off 25m
                  </MenuItem>
                  <MenuItem
                    value='on_10_off_20'
                  >
                    On 10m off 20m
                  </MenuItem>
                  <MenuItem
                    value='on_20_off_20'
                  >
                    On 20m off 20m
                  </MenuItem>
                  <MenuItem
                    value='on_20_off_10'
                  >
                    On 20m off 10m
                  </MenuItem>
                </Select>
              </FormControl>
              <Stack
                direction='row'
                wrap='nowrap'
                itemStyles={[{
                  flex: 1,
                }]}
              >
                <FormControl
                  size='small'
                  variant='outlined'
                  style={{
                    width: '100%',
                  }}
                >
                  <InputLabel
                    htmlFor={`weekend_start_time`}
                  >
                    Start
                  </InputLabel>
                  <OutlinedInput
                    id={`weekend_start_time`}
                    name={`weekend_start_time`}
                    label='Interval'
                    type='time'
                    disabled={loading}
                    value={tempInput[`weekend_start_time`]}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  size='small'
                  variant='outlined'
                  style={{
                    width: '100%',
                  }}
                >
                  <InputLabel
                    htmlFor={`weekend_stop_time`}
                  >
                    End
                  </InputLabel>
                  <OutlinedInput
                    id={`weekend_stop_time`}
                    name={`weekend_stop_time`}
                    label='Interval'
                    type='time'
                    disabled={loading}
                    value={tempInput[`weekend_stop_time`]}
                    onChange={handleChange}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                </FormControl>
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <RuleTitle>
                Asleep
              </RuleTitle>
              <FormControl
                size='small'
                variant='outlined'
                style={{
                  width: '100%',
                }}
              >
                <InputLabel
                  htmlFor={`weekend_sleep_interval`}
                >
                  Interval
                </InputLabel>
                <Select
                  id={`weekend_sleep_interval`}
                  name={`weekend_sleep_interval`}
                  labelId={`weekend_sleep_interval`}
                  label='Interval'
                  disabled={loading}
                  value={tempInput[`weekend_sleep_interval`]}
                  onChange={handleChange}
                >
                  <MenuItem
                    value='always_on'
                  >
                    Always on
                  </MenuItem>
                  <MenuItem
                    value='always_off'
                  >
                    Always off
                  </MenuItem>
                  <MenuItem
                    value='on_5_off_55'
                  >
                    On 5m off 55m
                  </MenuItem>
                  <MenuItem
                    value='on_5_off_25'
                  >
                    On 5m off 25m
                  </MenuItem>
                  <MenuItem
                    value='on_10_off_20'
                  >
                    On 10m off 20m
                  </MenuItem>
                  <MenuItem
                    value='on_20_off_20'
                  >
                    On 20m off 20m
                  </MenuItem>
                  <MenuItem
                    value='on_20_off_10'
                  >
                    On 20m off 10m
                  </MenuItem>
                </Select>
              </FormControl>
              <Stack
                direction='row'
                wrap='nowrap'
                itemStyles={[{
                  flex: 1,
                }]}
              >
                <FormControl
                  size='small'
                  variant='outlined'
                  style={{
                    width: '100%',
                  }}
                >
                  <InputLabel
                    htmlFor={`weekend_stop_time`}
                  >
                    Start
                  </InputLabel>
                  <OutlinedInput
                    id={`weekend_stop_time`}
                    name={`weekend_stop_time`}
                    label='Interval'
                    type='time'
                    disabled
                    value={tempInput[`weekend_stop_time`]}
                    onChange={handleChange}
                  />
                </FormControl>
                <FormControl
                  size='small'
                  variant='outlined'
                  style={{
                    width: '100%',
                  }}
                >
                  <InputLabel
                    htmlFor={`weekend_start_time`}
                  >
                    End
                  </InputLabel>
                  <OutlinedInput
                    id={`weekend_start_time`}
                    name={`weekend_start_time`}
                    label='Interval'
                    type='time'
                    disabled
                    value={tempInput[`weekend_start_time`]}
                    onChange={handleChange}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </TabPanel>
        </TabContext> */}
        <Box mx={2} mb={2}>
          <Footer
            success={success}
            error={error}
            loading={loading}
            touched={touched}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        </Box>
      </Stack>
    </AutomationRuleCardNoPadding>
  )
}

export default Schedule
